import { action, createStore, createTypedHooks } from 'easy-peasy';
import LogRocket from 'logrocket';

import { UserState } from './user';
import { UserStateModel } from './user/types';
import { RateContractsState } from './rateContracts';
import { RateContractsStateModel } from './rateContracts/types';
import { TransfersState } from './transfers';
import { TransfersStateModel } from './transfers/types';
import { CurrenciesState } from './currencies';
import { CurrenciesStateModel } from './currencies/types';
import { ReferenceDataState } from './referenceData';
import { ReferenceDataStateModel } from './referenceData/types';
import { RecipientsState } from './recipients';
import { RecipientsStateModel } from './recipients/types';
import { SessionState } from './session';
import { SessionStateModel } from './session/types';
import { InvoicesState } from './invoices';
import { InvoicesStateModel } from './invoices/types';
import { BulkPaymentsState } from './bulkPayments';
import { BulkPaymentsStateModel } from './bulkPayments/types';
import { BalancesState } from './balances';
import { BalancesStateModel } from './balances/types';
import { ExternalHedgesState } from './externalHedges';
import { ExternalHedgesStateModel } from './externalHedges/types';
import { FeatureFlagsState } from './featureFlags';
import { recursivelySanitiseAction } from 'settings/analytics/logRocket';
import { PaymentRunsState } from './paymentRuns';
import { PaymentRunsStateModel } from './paymentRuns/types';
import { EntityState } from './entity';
import { EntityStateModel } from './entity/types';
import { UtilsState } from './utils';
import { FeatureFlagsStateModel } from './featureFlags/types';
import { UtilsStateModel } from './utils/types';
import { LimitOrdersState } from './limitOrders';
import { LimitOrdersStateModel } from './limitOrders/types';

const typedHooks = createTypedHooks<StateModel>();

export const useStoreActions = typedHooks.useStoreActions;
export const useStoreState = typedHooks.useStoreState;

export interface StateModel {
  UserState: UserStateModel;
  RateContractsState: RateContractsStateModel;
  TransfersState: TransfersStateModel;
  CurrenciesState: CurrenciesStateModel;
  ReferenceDataState: ReferenceDataStateModel;
  RecipientsState: RecipientsStateModel;
  SessionState: SessionStateModel;
  InvoicesState: InvoicesStateModel;
  BulkPaymentsState: BulkPaymentsStateModel;
  BalancesState: BalancesStateModel;
  ExternalHedgesState: ExternalHedgesStateModel;
  FeatureFlagsState: FeatureFlagsStateModel;
  PaymentRunsState: PaymentRunsStateModel;
  EntityState: EntityStateModel;
  UtilsState: UtilsStateModel;
  LimitOrdersState: LimitOrdersStateModel;
}

export const stateModel: StateModel = {
  UserState,
  RateContractsState,
  TransfersState,
  CurrenciesState,
  ReferenceDataState,
  RecipientsState,
  SessionState,
  InvoicesState,
  BulkPaymentsState,
  BalancesState,
  ExternalHedgesState,
  FeatureFlagsState,
  PaymentRunsState,
  EntityState,
  UtilsState,
  LimitOrdersState,
};

let initialState: {} | StateModel = {};
const store = createStore(
  {
    ...stateModel,
    reset: action<typeof initialState, void>(() => {
      return {
        ...initialState,
      };
    }),
  },
  {
    middleware: [
      LogRocket.reduxMiddleware({
        actionSanitizer: recursivelySanitiseAction,
      }),
    ],
  }
);

initialState = store.getState();

export const reset = () => {
  store.dispatch?.reset?.();
};

export default store;
