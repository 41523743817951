import styled from 'styled-components';
import { Col, Row } from 'components';

export const PayoutContactsFieldsWrapper = styled(Col)`
  flex: 1;
  max-width: 600px;
  align-items: flex-start;
`;

export const PayoutContactsLabelWrapper = styled(Row)`
  min-width: 156px;
`;
