import { FC, useMemo } from 'react';
import { Title } from 'components';
import { useQuery } from 'hooks';
import TitleLoader from '../TitleLoader/TitleLoader';
import StepAccountsForm, {
  IOnSaveValues,
  IOnSaveValuesArguments,
} from './components/StepAccountsForm/StepAccountsForm';

import { useTheme } from 'styled-components';
import { useStoreState } from 'state';
import { generateIntegrationEngineBankFeesAccountData } from 'utils/integrations';
import {
  isNonXeroPlatform,
  isXeroPlatform,
  TPlatformNames,
} from 'types/integrations';
import useIntegrationAccounts from '../../../hooks/useIntegrationAccounts';
import useIntegrationGetTaxRates from './useIntegrationGetTaxRates';

export type { IOnSaveValuesArguments };

interface OwnProps {
  onSaveValues: IOnSaveValues;
  onContinue: (goToNextAccountsStep: boolean) => void;
  platformName: TPlatformNames;
}

const StepAccountsFirst: FC<OwnProps> = ({
  onSaveValues,
  onContinue,
  platformName,
}) => {
  const theme = useTheme();
  const URLQuery = useQuery();
  const firstIntegration = URLQuery.get('firstIntegration') === 'true';
  const {
    bankFeesAccounts,
    bankTransferAccounts,
    defaultBankFeesAccount,
    isLoadingAccounts,
    allAccounts,
  } = useIntegrationAccounts(platformName);
  const { integrationEngineSettings } = useStoreState(
    ({ UserState }) => UserState
  );
  const isXeroIntegration = isXeroPlatform(platformName);
  const isNonXeroIntegration = isNonXeroPlatform(platformName);

  const {
    isLoadingTaxRates,
    taxRates,
    areTaxRatesRequired,
    savedTaxRateForBankFees,
  } = useIntegrationGetTaxRates(platformName);

  const defaultBankFeeAccount = useMemo(() => {
    if (isXeroIntegration) {
      const savedBankAccount = integrationEngineSettings?.accounts?.find(
        (account) => account.type === 'bankFeesAccount'
      );
      if (savedBankAccount) {
        return generateIntegrationEngineBankFeesAccountData(savedBankAccount);
      }
      if (firstIntegration && defaultBankFeesAccount) {
        return generateIntegrationEngineBankFeesAccountData(
          defaultBankFeesAccount
        );
      }
    }

    if (isNonXeroIntegration) {
      const savedBankAccount = integrationEngineSettings?.accounts?.find(
        (account) => account.type === 'bankTransferAccount'
      );
      if (savedBankAccount) {
        return generateIntegrationEngineBankFeesAccountData(savedBankAccount);
      }
      if (firstIntegration && defaultBankFeesAccount) {
        return generateIntegrationEngineBankFeesAccountData(
          defaultBankFeesAccount,
          defaultBankFeesAccount.id
        );
      }
    }

    return null;
  }, [
    isXeroIntegration,
    isNonXeroIntegration,
    firstIntegration,
    defaultBankFeesAccount,
    integrationEngineSettings,
  ]);

  if (isLoadingAccounts) {
    return <TitleLoader title="Getting expense accounts." />;
  }

  return (
    <>
      <Title mb mbValue={theme.spacing.xl}>
        What activity should we export to {platformName}?
      </Title>

      <StepAccountsForm
        platformName={platformName}
        allAccounts={allAccounts}
        feesAccounts={bankFeesAccounts}
        defaultBankFeesAccount={defaultBankFeeAccount}
        bankTransferAccounts={bankTransferAccounts}
        onContinue={onContinue}
        onSaveValues={onSaveValues}
        isLoadingTaxRates={isLoadingTaxRates}
        taxRates={taxRates}
        areTaxRatesRequired={areTaxRatesRequired}
        savedTaxRateForBankFees={savedTaxRateForBankFees}
      />
    </>
  );
};

export default StepAccountsFirst;
