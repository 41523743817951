import { FC, useMemo } from 'react';
import { Paragraph, Icon } from 'components';
import { Row } from 'components/shared/Row/Row';
import { useStoreState } from 'state';
import { useHistory } from 'react-router';
import { useTheme } from 'styled-components';
import { getTransactionPageLink } from 'utils/links';
import CounterTitle from '../CounterTitle/CounterTitle';
import { AccountsRow } from '../AccountsRow/AccountsRow.styles';
import {
  getTransactionTypeTitle,
  viewTransactionDetails,
} from 'utils/transactions';
import DropdownMenuCell from '../DropdownMenuCell/DropdownMenuCell';
import DirectionCell from '../DirectionCell/DirectionCell';
import AmountCell from '../AmountCell/AmountCell';
import DateCell from '../DateCell/DateCell';
import { TransactionWrapper } from './TransactionsMobile.styles';
import useSubscribeToTransactions from 'hooks/useSubscribeToTransactions';
import TransactionsPlaceholder from '../TransactionsPlaceholder/TransactionsPlaceholder';
import Button from '../Button/Button';
import useUrlValues from 'hooks/useUrlValues';
import { ITransaction } from 'types';

interface OwnProps {
  queryLimit?: number;
  withShowMore?: boolean;
  onlyTransfers?: boolean;
  transactionTypesToRender?: Array<ITransaction['type']>;
}

const TransactionsMobile: FC<OwnProps> = ({
  queryLimit,
  withShowMore = false,
  onlyTransfers,
  transactionTypesToRender,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const { transactions, setTransactions } = useSubscribeToTransactions({
    queryLimit,
  });
  const { setUrlValue } = useUrlValues();

  const transactionsToDisplay = useMemo(
    () =>
      transactions.filter((transaction) => {
        if (transactionTypesToRender) {
          return transactionTypesToRender.includes(transaction.type);
        }

        return true;
      }),
    [transactions, transactionTypesToRender]
  );

  if (!transactionsToDisplay.length) {
    return <TransactionsPlaceholder />;
  }

  return (
    <>
      <Row mb>
        <CounterTitle
          mr
          title={onlyTransfers ? 'Transfers' : 'Transactions'}
          count={transactionsToDisplay.length}
        />
        {withShowMore && (
          <Button
            ml
            mrValue={theme.spacing.xxs}
            variant="link"
            onClick={() => history.push(getTransactionPageLink())}
          >
            Show more
          </Button>
        )}
      </Row>
      <Row flexWrap="wrap" rowGap={theme.spacing.s}>
        {transactionsToDisplay.map((transaction) => {
          const currency = currencyByCode(transaction.mainCurrency);

          return (
            <TransactionWrapper
              onClick={() => viewTransactionDetails(setUrlValue, transaction)}
            >
              <AccountsRow>
                <Row gap={theme.spacing.xs}>
                  {currency && <Icon icon={currency.countryCode} />}
                  <Paragraph>
                    {getTransactionTypeTitle(transaction.type)}
                  </Paragraph>
                </Row>
                <DropdownMenuCell
                  transaction={transaction}
                  setTransactions={setTransactions}
                />
              </AccountsRow>
              <AccountsRow>
                <Row gap={theme.spacing.xxs}>
                  <Paragraph variant="bold">Amount:</Paragraph>
                  <DirectionCell
                    withTitle={false}
                    isReceivable={transaction.direction === 'in'}
                  />
                  <AmountCell
                    amount={transaction.amount}
                    currencyCode={transaction.mainCurrency}
                  />
                </Row>

                <Row gap={theme.spacing.xxs}>
                  <Paragraph variant="bold">Date:</Paragraph>
                  <DateCell value={transaction.transactedOn} />
                </Row>
              </AccountsRow>
            </TransactionWrapper>
          );
        })}
      </Row>
    </>
  );
};

export default TransactionsMobile;
