import { FC, useMemo, useState, useCallback, useEffect } from 'react';
import {
  IHedgeRecommendationResultV2,
  useGetHedgeRecommendations,
} from './useGetHedgeRecommendationsV2';
import Loader from 'components/shared/Loader/Loader';
import { generateTableColumns } from './generateTableColumnsV2';
import { Row, StaleInputSelect, Table } from 'components';
import { useStoreState } from 'state';
import { TableHeader } from 'components/shared/TableHeader/TableHeader.styles';
import { useTheme } from 'styled-components';
import PrebookHedgesPopup from './components/PrebookHedgesPopup/PrebookHedgesPopup';

const HedgeRecommendationsV2: FC = () => {
  const theme = useTheme();
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const [
    hedgeRecommendationForPrebook,
    setHedgeRecommendationForPrebook,
  ] = useState<IHedgeRecommendationResultV2>();
  const [selectedCurrency, setSelectedCurrency] = useState<string>();

  const {
    isLoadingRecommendations,
    recommendations,
    setRecommendations,
  } = useGetHedgeRecommendations({
    calendarInterval: 'month',
    numberOfRecommendations: 12,
  });

  const currenciesList = useMemo(() => {
    if (!recommendations) {
      return [];
    }

    return Object.keys(recommendations).map((currency) => ({
      id: currency,
      name: currency,
      value: currency,
      icon: currencyByCode(currency)?.countryCode,
    }));
  }, [recommendations, currencyByCode]);

  const tableData = useMemo(() => {
    if (!selectedCurrency || !recommendations) {
      return [];
    }

    return recommendations[selectedCurrency];
  }, [recommendations, selectedCurrency]);

  const onEditNewHedgeAmount = useCallback(
    (updatedData: IHedgeRecommendationResultV2) => {
      if (!recommendations || !selectedCurrency) {
        return;
      }

      const updatedRecommendation = recommendations[selectedCurrency].find(
        (recommendation) => recommendation.period === updatedData.period
      );

      // Check if the new hedge amount is different from the previous one
      if (
        !updatedRecommendation ||
        updatedRecommendation.newHedgeAmount === updatedData.newHedgeAmount
      ) {
        return;
      }

      setRecommendations((prevRecommendations) => {
        if (!prevRecommendations || !selectedCurrency) {
          return prevRecommendations;
        }

        return {
          ...prevRecommendations,
          [selectedCurrency]: prevRecommendations[
            selectedCurrency
          ].map((recommendation) =>
            recommendation.period === updatedData.period
              ? updatedData
              : recommendation
          ),
        };
      });
    },
    [recommendations, selectedCurrency, setRecommendations]
  );

  const tableColumns = useMemo(
    () =>
      generateTableColumns({
        setHedgeRecommendationForPrebook,
        onEditNewHedgeAmount,
      }),
    [onEditNewHedgeAmount]
  );

  useEffect(() => {
    // Set default currency
    if (!selectedCurrency && currenciesList[0]) {
      setSelectedCurrency(currenciesList[0].id);
    }
  }, [selectedCurrency, currenciesList]);

  if (isLoadingRecommendations) {
    return <Loader size="large" />;
  }

  return (
    <>
      <TableHeader>
        <Row gap={theme.spacing.m}>
          <StaleInputSelect
            id="cashflow-currencies"
            inputHeight="32px"
            style={{ minWidth: '132px' }}
            data={currenciesList}
            selected={selectedCurrency}
            onSelect={(item) => {
              setSelectedCurrency(item.value);
            }}
          />
        </Row>
      </TableHeader>

      <Table<IHedgeRecommendationResultV2>
        autoResetGlobalFilter={false}
        autoResetSortBy={false}
        autoResetFilters={false}
        data={tableData}
        globalFilter="text"
        sortable
        columns={tableColumns}
        isVirtualized
        minVisibleRows={6}
        defaultRowHeight={58}
      />

      {hedgeRecommendationForPrebook && (
        <PrebookHedgesPopup
          hedgeRecommendationForPrebook={hedgeRecommendationForPrebook}
          onClose={() => setHedgeRecommendationForPrebook(undefined)}
          setRecommendations={setRecommendations}
        />
      )}
    </>
  );
};

export default HedgeRecommendationsV2;
