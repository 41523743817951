import { FC, useEffect, useState } from 'react';
import { Subtitle, Title } from 'components/shared/Typography/Typography';
import Button from 'components/shared/Button/Button';
import { Popup, Row } from 'components';
import { useTheme } from 'styled-components';
import { useHistory, useLocation } from 'react-router';
import auth from 'services/auth';
import { TotpMultiFactorGenerator } from 'firebase/auth';
import useMFASetup from 'hooks/useMFASetup';
import { useStoreState } from 'state';
import { isEntityAddAuthenticatorDeferred } from './utils';
import { deferEntityAddAuthenticator } from 'services/entities';
import { getTotpSettingsPageLink, Notify } from 'utils';
import { errorHandler } from 'utils/errors';
import { localStorageInstance } from 'utils/localStorage';

const AddBackupMethodPopup: FC = () => {
  const theme = useTheme();
  const history = useHistory();
  const { pathname } = useLocation();
  const { isRegisteredAndReadyForMFASetup, isPhoneVerified } = useMFASetup();
  const { entityId, userEntity } = useStoreState((state) => state.UserState);

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const isDeferred = isEntityAddAuthenticatorDeferred(userEntity);
  const isInRestrictedRoute =
    pathname.includes('company-registration') ||
    pathname.includes('settings/totp') ||
    pathname.includes('access-error');

  useEffect(() => {
    const hasBeenClosed = localStorageInstance.getItem<boolean>(
      'addBackupMethodPopupHasBeenClosed'
    );

    const isTotpEnrolled = auth.currentUser?.multiFactor.enrolledFactors.find(
      (item) => item.factorId === TotpMultiFactorGenerator.FACTOR_ID
    );

    if (
      isRegisteredAndReadyForMFASetup &&
      isPhoneVerified &&
      !isInRestrictedRoute &&
      !isTotpEnrolled &&
      !hasBeenClosed &&
      !isDeferred
    ) {
      setIsOpen(true);
    }
  }, [
    isRegisteredAndReadyForMFASetup,
    isPhoneVerified,
    isInRestrictedRoute,
    isDeferred,
  ]);

  const onClose = () => {
    setIsOpen(false);
    localStorageInstance.setItem('addBackupMethodPopupHasBeenClosed', true);
  };

  const onAddAuthenticator = () => history.push(getTotpSettingsPageLink());

  const onDeferEntityAddAuthenticator = async () => {
    if (!entityId) {
      return;
    }

    try {
      setIsLoading(true);
      const { data } = await deferEntityAddAuthenticator(entityId);

      if (data.success) {
        Notify.success('Suggestion hidden for 1 month');
        onClose();
      } else {
        errorHandler(data);
      }
    } catch (error: any) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Popup
      HeaderContent={<Title variant="h4">Add a backup method</Title>}
      FooterContent={
        <Row flex={1} gap={theme.spacing.m}>
          <Row gap={theme.spacing.m}>
            <Button
              disabled={isLoading}
              isLoading={isLoading}
              onClick={onAddAuthenticator}
            >
              Add authenticator
            </Button>

            <Button
              disabled={isLoading}
              isLoading={isLoading}
              variant="secondary"
              onClick={onClose}
            >
              Skip for now
            </Button>
          </Row>

          <Button
            disabled={isLoading}
            variant="link"
            onClick={onDeferEntityAddAuthenticator}
          >
            Defer for 1 month
          </Button>
        </Row>
      }
      width="680px"
      onClose={onClose}
    >
      <Subtitle variant="bold">
        Set up a backup multi-factor authentication method using your favourite
        Authenticator app.
      </Subtitle>
    </Popup>
  );
};

export default AddBackupMethodPopup;
