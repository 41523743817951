import styled from 'styled-components';
import Card from '../Card/Card.styles';

export const MaintenanceBannerWrapper = styled(Card)<{ fullWidth: boolean }>`
  width: 100%;
  max-width: ${({ fullWidth, theme }) =>
    fullWidth ? '100%' : theme.cardSizes.xs};
  background-color: ${({ theme }) => theme.color.redLight_1};
  border: 1px solid ${({ theme }) => theme.color.red};
  margin-bottom: ${({ theme }) => theme.spacing.xxl};
`;
