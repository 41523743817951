import dayjs from 'dayjs';
import { Paragraph } from 'components';
import { IBulkPayment, BULK_PAYMENT_STATUS } from 'types';
import { isDateOverdue } from 'utils/dates';
import { DATE_FORMAT_SHORT_MONTH_NAME } from 'variables';

const FundByCell: React.FC<{
  bulkPayment: IBulkPayment;
}> = ({ bulkPayment }) => {
  const {
    payByDate,
    payByTimeGMT,
    scheduledPaymentDate,
    fundedTimestamp,
    status,
  } = bulkPayment;

  const isAwaitingPayment = status === BULK_PAYMENT_STATUS.awaitingPayment;
  // Show red date if isAwaitingPayment and scheduled payment date is in the past
  const isOverdue =
    isAwaitingPayment &&
    !!scheduledPaymentDate &&
    isDateOverdue(scheduledPaymentDate);

  return (
    <Paragraph color={isOverdue ? 'red' : 'dark'}>
      {isAwaitingPayment && payByDate && (
        <>
          {dayjs(payByDate).format(DATE_FORMAT_SHORT_MONTH_NAME)} @{' '}
          {dayjs(payByTimeGMT, 'HH:mm').format('HH:mm')}
        </>
      )}

      {!isAwaitingPayment && fundedTimestamp && (
        <>
          Funded on{' '}
          {dayjs(fundedTimestamp).format(DATE_FORMAT_SHORT_MONTH_NAME)}
        </>
      )}
    </Paragraph>
  );
};

export default FundByCell;
