import Button from '../Button/Button';

interface Props {
  onSignInWithGoogle: () => void;
  disabled?: boolean;
}
const StaleGoogleSignInButton: React.FC<Props> = ({
  onSignInWithGoogle,
  disabled = false,
}) => (
  <Button
    mb
    variant="secondary"
    onClick={(event) => {
      event.preventDefault();
      onSignInWithGoogle();
    }}
    type="button"
    icon="google-ico"
    disabled={disabled}
  >
    Log in with Google
  </Button>
);

export default StaleGoogleSignInButton;
