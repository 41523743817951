import {
  FC,
  useMemo,
  useState,
  useCallback,
  Dispatch,
  SetStateAction,
} from 'react';
import { CancellingBalance, ContextMenu } from 'components';
import { ITransaction } from 'types/transactions';
import { useHistory } from 'react-router';
import { getInvoiceDetailsLink, getTransfersPageLink } from 'utils/links';
import { useStoreState } from 'state';
import { canUseRateContract } from 'utils';
import { viewTransactionDetails } from 'utils/transactions';
import {
  useAllocatePrebooksPermissionsCheck,
  useCreateTransfersPermissionsCheck,
} from 'hooks/useSpecificPermissionsCheck';
import useUrlValues from 'hooks/useUrlValues';
import PopupSendRemittance from '../PopupSendRemittance/PopupSendRemittance';
import { TRANSFER_STATUS, CONTRACT_STATUS, CONVERSION_STATUS } from 'types';

interface IOwnProps {
  transaction: ITransaction;
  setTransactions: Dispatch<SetStateAction<ITransaction[]>>;
}

const DropdownMenuCell: FC<IOwnProps> = ({ transaction, setTransactions }) => {
  const history = useHistory();
  const [showCancelPopup, setShowCancelPopup] = useState(false);
  const [showRemittancePopup, setShowRemittancePopup] = useState(false);
  const { rateContractById } = useStoreState(
    (state) => state.RateContractsState
  );
  const { transferById } = useStoreState((state) => state.TransfersState);
  const { setUrlValue } = useUrlValues();

  const hasTransfersCreatePermission = useCreateTransfersPermissionsCheck();
  const hasAllocatePrebooksPermission = useAllocatePrebooksPermissionsCheck();

  const sourceRecordId = transaction.sourceRecordId;
  const rateContract = rateContractById(sourceRecordId);
  const transfer = transferById(sourceRecordId);

  const onCancel = useCallback(() => {
    setShowCancelPopup(true);
  }, [setShowCancelPopup]);

  const contextMenuData = useMemo(() => {
    const dataToUse = [
      {
        id: 'seeDetails',
        title: 'See details',
        onClick: () => viewTransactionDetails(setUrlValue, transaction),
      },
    ];

    if (rateContract) {
      const { remainingBuyAmount, buyCurrency, id, invoiceId } = rateContract;

      if (
        canUseRateContract({ rateContract, hasAllocatePrebooksPermission }) &&
        hasTransfersCreatePermission
      ) {
        dataToUse.push({
          id: 'transferNow',
          title: 'Transfer now',
          onClick: () => {
            history.push(
              getTransfersPageLink({
                predefinedBuyAmount: remainingBuyAmount.toString(),
                predefinedBuyCurrency: buyCurrency,
                predefinedRateContractId: id,
              })
            );
          },
        });
      }

      if (invoiceId) {
        dataToUse.push({
          id: 'invoiceDetails',
          title: 'Invoice details',
          onClick: () => history.push(getInvoiceDetailsLink({ invoiceId })),
        });
      }

      // Add cancel action for rate contracts when:
      // 1. Status is awaiting_prepayment
      // 2. Contract can be used (matching PrebookInner's condition)
      if (
        rateContract.status === CONTRACT_STATUS.awaitingPrepayment ||
        canUseRateContract({ rateContract, hasAllocatePrebooksPermission })
      ) {
        dataToUse.push({
          id: 'cancel',
          title:
            rateContract.status === CONTRACT_STATUS.awaitingPrepayment
              ? 'Cancel'
              : 'Cancel Remaining Amount',
          onClick: onCancel,
        });
      }
    }

    // Add cancel action for conversions in awaiting_payment or funded status
    if (
      transaction.type === 'fxExchange' &&
      (transaction.status === CONVERSION_STATUS.awaitingPayment ||
        transaction.status === CONVERSION_STATUS.funded)
    ) {
      dataToUse.push({
        id: 'cancel',
        title: 'Cancel',
        onClick: onCancel,
      });
    }

    if (transfer && transfer.status === TRANSFER_STATUS.paymentSent) {
      dataToUse.push({
        id: 'sendRemittance',
        title: 'Send remittance email',
        onClick: () => setShowRemittancePopup(true),
      });
    }

    return dataToUse;
  }, [
    rateContract,
    transaction,
    transfer,
    setUrlValue,
    hasAllocatePrebooksPermission,
    hasTransfersCreatePermission,
    history,
    onCancel,
  ]);

  return (
    <>
      <ContextMenu portal list={contextMenuData} />

      {transfer && showRemittancePopup && (
        <PopupSendRemittance
          transferId={transfer.id}
          transferRecipient={transfer.recipient}
          onClose={() => setShowRemittancePopup(false)}
        />
      )}
      {showCancelPopup && (
        <CancellingBalance
          onClose={(isCancelled?: boolean) => {
            setShowCancelPopup(false);
            if (isCancelled) {
              setTransactions((prev) =>
                prev.map((transactionInner) =>
                transactionInner.sourceRecordId === transaction.sourceRecordId
                  ? {
                      ...transactionInner,
                      status:
                        transaction.type === 'fxExchange'
                          ? CONVERSION_STATUS.cancelled
                          : CONTRACT_STATUS.cancelled,
                    }
                  : transactionInner
                )
              );
            }
          }}
          type={transaction.type === 'fxExchange' ? 'conversion' : 'contract'}
          cancelRecordId={transaction.sourceRecordId}
        />
      )}
    </>
  );
};

export default DropdownMenuCell;
