import { FC, useEffect, useState } from 'react';
import { StyledForm } from 'components/shared/Form/Form.styles';
import { Row, StaleInputSelect, Title } from 'components';
import { Controller, useForm } from 'react-hook-form7';
import InputBase from 'components/shared/InputBase/InputBase';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import InputDateUncontrolled from 'components/shared/InputDateUncontrolled/InputDateUncontrolled';
import { useTheme } from 'styled-components';
import { IWalletTransaction } from 'pages/Statements/components/StatementsTable/types';
import { parseIntoCurrencyString } from 'utils';
import dayjs from 'dayjs';
import { DATE_FORMAT, DB_DATE_FORMAT, ERROR_MESSAGES } from 'variables';
import Field from 'components/shared/Field/Field.styles';
import { getAccounts } from 'services/firebase';
import { IIntegrationSettingsAccount } from 'types';
import { errorHandler } from 'utils/errors';
import { useStoreState } from 'state';
import { writeBankTransferToSourceSystem } from 'services/firebase/integrationEngine';

interface IOwnProps {
  record: IWalletTransaction;
  onClose: () => void;
  onSuccess: () => void;
}

interface ISimplifiedAccount {
  id: string;
  sourceSystemId?: string;
  name: string;
}

interface IFormValues {
  transferAmount: number;
  transferDate: string;
  transferTo?: ISimplifiedAccount;
  transferFrom?: ISimplifiedAccount;
  reference?: string;
  shouldAutomaticallyMarkAsReconciled: boolean;
}

const WriteBankTransferForm: FC<IOwnProps> = ({
  record,
  onClose,
  onSuccess,
}) => {
  const theme = useTheme();
  const { entityId } = useStoreState((state) => state.UserState);
  const { control, setValue, handleSubmit } = useForm<IFormValues>({
    defaultValues: {
      transferAmount:
        Math.abs(record.amount) -
        record.writableResources
          .filter((wr) => wr.type === 'paymentFee')
          .reduce((acc, wr) => acc + (wr.amount ?? 0), 0),
      transferDate: dayjs(record.settledOnTimestamp).format(DATE_FORMAT),
      reference: record.reference,
      shouldAutomaticallyMarkAsReconciled: false,
    },
  });
  const [sourceSystemAccounts, setSourceSystemAccounts] = useState<
    IIntegrationSettingsAccount[]
  >([]);
  const [accountSearchValue, setAccountSearchValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data: IFormValues) => {
    try {
      if (isLoading) {
        return;
      }

      setIsLoading(true);
      // this is prevented by form validation in advance, but we still have to check because of shared types
      if (!data.transferFrom || !data.transferTo) {
        return;
      }

      // Take sourceSystemId if it exists because accounts from source system have id as sourceSystemId,
      // while accounts from currentBalances have both id and sourceSystemId
      const accountFromId =
        data.transferFrom.sourceSystemId ?? data.transferFrom.id;
      const accountToId = data.transferTo.sourceSystemId ?? data.transferTo.id;
      if (entityId) {
        await writeBankTransferToSourceSystem(
          {
            hedgeFlowsId: record.sourceId,
            isReconciled: data.shouldAutomaticallyMarkAsReconciled,
            sourceSystemFromAccountId: accountFromId,
            sourceSystemToAccountId: accountToId,
            amount: Math.abs(data.transferAmount),
            currency: record.currency,
            reference: data.reference ?? '',
            postingDate: dayjs(data.transferDate, DATE_FORMAT).format(
              DB_DATE_FORMAT
            ),
          },
          entityId
        );
      }
      onClose();
      onSuccess();
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const onError = (errors: any) => {
    console.log(errors);
  };

  useEffect(() => {
    if (entityId) {
      getAccounts(entityId)
        .then((res) => {
          if (res.data.data) {
            const accountsForRecordCurrencyWithoutHedgeFlowsAccounts = res.data.data.filter(
              (account) =>
                account.currency === record.currency &&
                !account.name.includes('HedgeFlows')
            );
            setSourceSystemAccounts(
              accountsForRecordCurrencyWithoutHedgeFlowsAccounts
            );

            const hedgeflowsAccountForRecordCurrency = res.data.data.find(
              (balance) =>
                balance.currency === record.currency &&
                balance.name.includes('HedgeFlows')
            );

            if (!hedgeflowsAccountForRecordCurrency) {
              return;
            }

            if (record.type === 'Transfer in') {
              setValue('transferTo', hedgeflowsAccountForRecordCurrency);
            }

            if (record.type === 'Transfer out') {
              setValue('transferFrom', hedgeflowsAccountForRecordCurrency);
            }
          }
        })
        .catch(errorHandler);
    }
  }, [entityId, record.currency, record.type, setValue]);

  const sourceSystemAccountsAfterSearch = sourceSystemAccounts.filter(
    (account) =>
      account.name.toLowerCase().includes(accountSearchValue.toLowerCase())
  );

  return (
    <StyledForm
      id="write-bank-transfer-form"
      gap={theme.spacing.xl}
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      <Title variant="h5">Create a bank transfer in your Xero?</Title>

      <Row alignSelf="stretch">
        <Controller
          name="transferAmount"
          control={control}
          rules={{ required: ERROR_MESSAGES.requiredField }}
          render={({ field: { value } }) => (
            <Field>
              <InputBase
                label="Transfer amount"
                value={parseIntoCurrencyString(value)}
                disabled
              />
            </Field>
          )}
        />
        <Controller
          name="transferDate"
          control={control}
          rules={{ required: ERROR_MESSAGES.requiredField }}
          render={({ field: { value, name } }) => (
            <Field>
              <InputDateUncontrolled
                id={name}
                label="Transfer date"
                value={value}
              />
            </Field>
          )}
        />
      </Row>

      <Row alignSelf="stretch">
        <Controller
          name="transferFrom"
          control={control}
          rules={{ required: ERROR_MESSAGES.requiredField }}
          render={({ field: { onChange, value, name } }) => (
            <Field flex={1}>
              <StaleInputSelect
                style={{ flex: 1 }}
                id={name}
                data={sourceSystemAccountsAfterSearch.map((item) => ({
                  name: item.name,
                  id: item.id,
                  value: { ...item },
                }))}
                label="Transfer from"
                selected={value}
                onSelect={(item) => {
                  onChange(item.value);
                }}
                disabled={record.type === 'Transfer out'}
                withSearch
                searchValue={accountSearchValue}
                onSearch={(event) =>
                  setAccountSearchValue(event.currentTarget.value)
                }
              />
            </Field>
          )}
        />
        <Controller
          name="transferTo"
          control={control}
          rules={{ required: ERROR_MESSAGES.requiredField }}
          render={({ field: { onChange, value, name } }) => (
            <Field flex={1}>
              <StaleInputSelect
                style={{ flex: 1 }}
                id={name}
                data={sourceSystemAccountsAfterSearch.map((item) => ({
                  name: item.name,
                  id: item.id,
                  value: { ...item },
                }))}
                label="Transfer to"
                selected={value}
                onSelect={(item) => {
                  onChange(item.value);
                }}
                disabled={record.type === 'Transfer in'}
                withSearch
                searchValue={accountSearchValue}
                onSearch={(event) =>
                  setAccountSearchValue(event.currentTarget.value)
                }
              />
            </Field>
          )}
        />
      </Row>

      <Controller
        name="reference"
        control={control}
        render={({ field: { value, onChange } }) => (
          <Field>
            <InputBase label="Reference" value={value} onChange={onChange} />
          </Field>
        )}
      />

      <Controller
        name="shouldAutomaticallyMarkAsReconciled"
        control={control}
        render={({ field: { value, onChange } }) => (
          <Field>
            <Checkbox
              label="Automatically mark as reconciled"
              checked={value}
              onChange={(event) => {
                onChange(event.target.checked);
              }}
            />
          </Field>
        )}
      />
    </StyledForm>
  );
};

export default WriteBankTransferForm;
