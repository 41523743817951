import { Loader, Paragraph } from 'components';
import { IHedgeRecommendationResult } from 'pages/HedgeRecommendations/useGetHedgeRecommendations';
import { FC } from 'react';
import { parseRateWithPrecision } from 'utils';

interface IOwnProps {
  record: IHedgeRecommendationResult;
}

const IndicativeRateCell: FC<IOwnProps> = ({ record }) => {
  if (!record.rate) {
    return <Loader size="small" />;
  }

  return (
    <Paragraph>
      {record.rate === 'N/A'
        ? record.rate
        : parseRateWithPrecision(record.rate)}
    </Paragraph>
  );
};

export default IndicativeRateCell;
