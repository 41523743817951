import { useState, useEffect } from 'react';
import { getTransactions } from 'services/transactions';
import { ITransaction } from 'types/transactions';
import { Notify } from 'utils';

export const useGetTransactions = (queryLimit?: number) => {
  const [isLoadingTransactions, setIsLoadingTransactions] = useState<boolean>(
    false
  );
  const [transactions, setTransactions] = useState<ITransaction[]>([]);

  useEffect(() => {
    const fetchTransactions = async () => {
      setIsLoadingTransactions(true);
      try {
        const results = await getTransactions(queryLimit);
        if (!!results?.data?.data) {
          setTransactions(results.data.data);
        }
      } catch (error: any) {
        Notify.error(error.message);
      } finally {
        setIsLoadingTransactions(false);
      }
    };

    fetchTransactions();
  }, [queryLimit, setTransactions]);

  return { isLoadingTransactions, transactions, setTransactions };
};
