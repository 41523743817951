import { FC, useRef, useEffect, useState, useMemo } from 'react';
import { IMaskInput } from 'react-imask';
import cx from 'classnames';
import {
  ErrorParagraph,
  FieldCol,
  ConvertRow,
  ConvertParagraph,
  ConvertSvg,
  RateTypeSubtitle,
  OutcomeSubtitle,
  RateTypeRow,
  Wrapper,
} from './AddCurrency.styles';

import {
  StaleInputSelect,
  StaleSwitch,
  Row,
  Paragraph,
  Subtitle,
  StaleRadioButton,
  Popup,
  Title,
  Col,
} from 'components';
import { useStoreActions, useStoreState } from 'state';
import {
  getRateByRateType,
  Notify,
  parseRateWithPrecision,
  roundToPrecision,
} from 'utils';
import { FOLLOWED_CURRENCY_RATE_TYPE, IFollowedCurrencyPair } from 'types';
import useCurrencyRate from 'hooks/useCurrencyRate';
import useUrlValues from 'hooks/useUrlValues';
import Button from 'components/shared/Button/Button';

const AddCurrency: FC = () => {
  const {
    buyCurrencies,
    currencyByCode,
    followedCurrencyById,
    sellCurrencies,
  } = useStoreState((state) => state.CurrenciesState);
  const { entityCurrencyCode } = useStoreState((state) => state.UserState);
  const {
    addFollowedCurrencyPair,
    updateFollowedCurrencyPair,
    deleteFollowedCurrencyPair,
  } = useStoreActions((actions) => actions.CurrenciesState);
  const { systemVariables } = useStoreState(
    (state) => state.ReferenceDataState
  );

  const { foreignCurrency, currencyForEdit, setUrlValue } = useUrlValues(
    'foreignCurrency',
    'currencyForEdit'
  );

  const [isLoading, setIsLoading] = useState(false);
  const [rateType, setRateType] = useState<FOLLOWED_CURRENCY_RATE_TYPE>('pay');

  const [sellCurrency, setSellCurrency] = useState(
    currencyByCode(entityCurrencyCode)
  );
  const [buyCurrency, setBuyCurrency] = useState(
    currencyByCode(foreignCurrency ?? systemVariables?.defaultBuyCurrency)
  );
  const [invert, setInvert] = useState(false);

  const [outcomeWidth, setOutcomeWidth] = useState(10);
  const [outcomeVal, setOutcomeVal] = useState('');
  const [canChangeRate, setCanChangeRate] = useState(false);
  const [outcomeFocus, setOutcomeFocus] = useState(false);
  const [
    followedCurrency,
    setFollowedCurrency,
  ] = useState<IFollowedCurrencyPair | null>(null);

  const isSameCurrencies = useMemo(
    () => sellCurrency?.name === buyCurrency?.name,
    [sellCurrency, buyCurrency]
  );

  const { rate, isLoading: isRateLoading } = useCurrencyRate({
    sellCurrency: sellCurrency?.code,
    buyCurrency: buyCurrency?.code ?? systemVariables?.defaultBuyCurrency,
    predicate: !!buyCurrency && !!sellCurrency,
    useCachedRate: true,
  });

  const rateToUse = useMemo(() => {
    const rateByRateType = getRateByRateType(rate, rateType, invert);
    if (!isSameCurrencies && rateType && rateByRateType) {
      return roundToPrecision(rateByRateType, 4);
    }
    return null;
  }, [invert, rate, rateType, isSameCurrencies]);

  const hideInput = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (currencyForEdit) {
      const followedCurrencyForEdit = followedCurrencyById(currencyForEdit);
      setFollowedCurrency(followedCurrencyForEdit);

      if (followedCurrencyForEdit) {
        const buyCurrency = currencyByCode(followedCurrencyForEdit.buyCurrency);
        const sellCurrency = currencyByCode(
          followedCurrencyForEdit.sellCurrency
        );

        setBuyCurrency(buyCurrency);
        setSellCurrency(sellCurrency);
        setInvert(!!followedCurrencyForEdit.invertRate);

        if (followedCurrencyForEdit.targetRate) {
          setOutcomeVal(followedCurrencyForEdit.targetRate.toString());
        }

        if (followedCurrencyForEdit.rateType) {
          setRateType(followedCurrencyForEdit.rateType);
        }
      }
    }
  }, [currencyForEdit, currencyByCode, followedCurrencyById]);

  useEffect(() => {
    if (hideInput.current) {
      setOutcomeWidth(hideInput.current.getBoundingClientRect().width);
    }
  }, [outcomeVal, hideInput]);

  const onClose = () => {
    setUrlValue({
      foreignCurrency: '',
      currencyForEdit: '',
      addCurrency: '',
    });
  };

  const onAddCurrency = async () => {
    if (!buyCurrency || !sellCurrency) {
      return;
    }

    setIsLoading(true);

    const response = await addFollowedCurrencyPair({
      buyCurrency: buyCurrency.code,
      sellCurrency: sellCurrency.code,
      targetRate: outcomeVal ? parseFloat(outcomeVal) : undefined,
      invertRate: invert,
      rateType: rateType ?? null,
    });

    setIsLoading(false);

    if (response && response.success) {
      Notify.success('Currency pair added successfully');
      onClose();
    }
  };

  const onUpdateCurrency = async () => {
    if (!buyCurrency || !sellCurrency || !currencyForEdit) {
      return;
    }

    setIsLoading(true);

    const response = await updateFollowedCurrencyPair({
      followedCurrencyData: {
        buyCurrency: buyCurrency.code,
        sellCurrency: sellCurrency.code,
        targetRate: outcomeVal ? parseFloat(outcomeVal) : undefined,
        invertRate: invert,
        rateType: rateType ?? null,
      },
      followedCurrencyId: currencyForEdit,
    });

    setIsLoading(false);

    if (response && response.success) {
      Notify.success('Currency pair updated successfully');
      onClose();
    }
  };

  const onDeleteCurrencyPair = async () => {
    if (currencyForEdit) {
      setIsLoading(true);
      const response = await deleteFollowedCurrencyPair(currencyForEdit);

      if (response?.success) {
        Notify.success('Currency pair deleted successfully');
        onClose();
      }

      setIsLoading(false);
    }
  };

  const onDeleteChangeRate = async () => {
    setOutcomeVal('');

    if (!buyCurrency || !sellCurrency || !currencyForEdit) {
      return;
    }

    setIsLoading(true);

    const response = await updateFollowedCurrencyPair({
      followedCurrencyData: {
        buyCurrency: buyCurrency.code,
        sellCurrency: sellCurrency.code,
        targetRate: null,
        invertRate: invert,
        rateType: rateType ?? null,
      },
      followedCurrencyId: currencyForEdit,
    });

    setIsLoading(false);

    if (response && response.success) {
      Notify.success('Currency pair updated successfully');
      onClose();
    }
  };

  return (
    <Popup
      onClose={onClose}
      width="439px"
      HeaderContent={
        <Title variant="h3">
          {currencyForEdit
            ? 'Edit followed currency pair'
            : 'Add currency pair to follow'}
        </Title>
      }
      FooterContent={
        <Col flex={1} alignItems="center">
          <Button
            disabled={isLoading || isRateLoading || isSameCurrencies}
            onClick={currencyForEdit ? onUpdateCurrency : onAddCurrency}
            isLoading={isLoading || isRateLoading}
          >
            {currencyForEdit ? 'Save this rate pair' : 'Follow this rate pair'}
          </Button>
          {currencyForEdit && (
            <Button mt variant="link" onClick={onDeleteCurrencyPair}>
              Delete this currency pair
            </Button>
          )}
        </Col>
      }
    >
      <Wrapper>
        <FieldCol>
          <Subtitle variant="bold">Your main currency</Subtitle>
          <StaleInputSelect
            id="sell-currency-input"
            data={sellCurrencies.map((item) => ({
              name: item.code,
              id: item.code,
              icon: item.countryCode,
              value: { ...item },
            }))}
            selected={sellCurrency}
            onSelect={(item) => {
              if (!canChangeRate) {
                setCanChangeRate(true);
              }

              setSellCurrency(item.value);
            }}
            autoFocus
          />
        </FieldCol>

        <FieldCol>
          <Subtitle variant="bold">Foreign currency</Subtitle>
          <StaleInputSelect
            id="buy-currency-input"
            data={buyCurrencies.map((item) => ({
              name: item.code,
              id: item.code,
              icon: item.countryCode,
              value: { ...item },
            }))}
            selected={buyCurrency}
            onSelect={(item) => {
              if (!canChangeRate) {
                setCanChangeRate(true);
              }

              setBuyCurrency(item.value);
            }}
          />
        </FieldCol>

        <FieldCol className="outcome">
          <div ref={hideInput} className="hide-input">
            {outcomeVal}
          </div>
          <Subtitle variant="bold">Your target</Subtitle>
          <Row>
            <label
              form="outcome"
              className={cx('outcome-wrap', outcomeFocus && 'focused')}
            >
              <OutcomeSubtitle>{`1 ${sellCurrency?.code} = `}</OutcomeSubtitle>

              <IMaskInput
                id="outcome"
                mask={Number}
                scale={4}
                radix="."
                padFractionalZeros
                normalizeZeros
                placeholder="0.00"
                className="input"
                maxLength={6}
                style={{ width: `${outcomeWidth}px` }}
                value={isSameCurrencies ? '1' : outcomeVal}
                // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
                onAccept={(value) => setOutcomeVal(value)}
                onFocus={() => setOutcomeFocus(true)}
                onBlur={() => setOutcomeFocus(false)}
                disabled={isSameCurrencies}
              />
              <Subtitle>{buyCurrency?.code}</Subtitle>
            </label>
            {currencyForEdit && followedCurrency?.targetRate && (
              <Button
                variant="link"
                icon="delete-ico"
                onClick={onDeleteChangeRate}
              />
            )}
          </Row>
        </FieldCol>

        <ConvertRow>
          <Row>
            <ConvertSvg>
              <use xlinkHref="#convert-ico" />
            </ConvertSvg>
            <Paragraph>{`1 ${
              invert ? buyCurrency?.code : sellCurrency?.code
            } = ${parseRateWithPrecision(rateToUse)} ${
              invert ? sellCurrency?.code : buyCurrency?.code
            }`}</Paragraph>
          </Row>
          <Row>
            <ConvertParagraph variant="bold">invert rate</ConvertParagraph>
            <StaleSwitch
              id={'swift'}
              isOn={invert}
              handleToggle={() => setInvert(!invert)}
              disabled={isSameCurrencies}
            />
          </Row>
        </ConvertRow>

        <RateTypeSubtitle variant="bold">Show me rates for:</RateTypeSubtitle>

        <RateTypeRow>
          <StaleRadioButton
            cardRow
            onChange={(item: any) => {
              setRateType(item.value);
            }}
            list={[
              {
                id: 'pay',
                value: 'pay',
                checked: rateType === 'pay',
                name: <Paragraph>Paying</Paragraph>,
              },
              {
                id: 'receive',
                value: 'receive',
                checked: rateType === 'receive',
                name: <Paragraph>Collecting</Paragraph>,
              },
              {
                id: 'mid',
                value: 'mid',
                checked: rateType === 'mid',
                name: <Paragraph>Average</Paragraph>,
              },
            ]}
            name="rateType"
          />
        </RateTypeRow>

        {isSameCurrencies && (
          <ErrorParagraph error>Currencies should not match</ErrorParagraph>
        )}
      </Wrapper>
    </Popup>
  );
};

export default AddCurrency;
