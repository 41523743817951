import { FC } from 'react';
import { useTheme } from 'styled-components';
import { useStoreState } from 'state';
import { withErrorBoundary } from 'hoc';
import useUrlValues from 'hooks/useUrlValues';
import useIsAwaitingReviewOrRegistration from 'hooks/useIsAwaitingReviewOrRegistration';
import { StaleAwaitingReviewNotification, Loader } from 'components';
import StandardDashboard from './components/StandardDashboard/StandardDashboard';
import FinanceDashboard from './components/FinanceDashboard/FinanceDashboard';
import AwaitingRegistrationDashboard from './components/AwaitingRegistrationDashboard/AwaitingRegistrationDashboard';
import AddCurrency from '../../components/shared/AddCurrency/AddCurrency';
import HFGuruTile from './components/HFGuruTile/HFGuruTile';
import TransferPrebookPopups from 'components/shared/TransferPrebookPopups/TransferPrebookPopups';
import TransactionsMobile from 'components/shared/TransactionsMobile/TransactionsMobile';
import TableTransactions from 'components/shared/TableTransactions/TableTransactions';
import useDeviceWidth from 'hooks/useDeviceWidth';
import MaintenanceBanner from 'components/shared/MaintenanceBanner/MaintenanceBanner';
const Dashboard: FC = () => {
  const theme = useTheme();
  const { isMobile } = useDeviceWidth();
  const {
    isAwaitingRegistration,
    isAwaitingComplianceReview,
  } = useIsAwaitingReviewOrRegistration();
  const {
    addCurrency: isAddCurrency,
    currencyForEdit: isEditCurrency,
  } = useUrlValues('addCurrency', 'currencyForEdit');
  const {
    userFirstName,
    isFxManagementPackageEnabled,
    isAutomationPackageEnabled,
    userEntity,
  } = useStoreState((state) => state.UserState);
  const notificationTitle = `Welcome ${userFirstName ?? ''}!`;

  const showHFGuru =
    !isAwaitingRegistration ||
    (isAwaitingRegistration &&
      !isAwaitingComplianceReview &&
      (isFxManagementPackageEnabled || isAutomationPackageEnabled));

  const showAwaitingRegistrationDashboard =
    !isFxManagementPackageEnabled &&
    !isAutomationPackageEnabled &&
    isAwaitingRegistration;

  const renderDashboardContent = () => {
    if (showAwaitingRegistrationDashboard) {
      return <AwaitingRegistrationDashboard />;
    }

    if (isFxManagementPackageEnabled) {
      return <FinanceDashboard />;
    }

    return <StandardDashboard />;
  };

  if (!userEntity?.name) {
    return <Loader size="large" />;
  }

  return (
    <>
      <StaleAwaitingReviewNotification
        title={notificationTitle}
        icon="celebrate-smile"
        bgColor={theme.gradients.blue}
      />

      <MaintenanceBanner fullWidth={false} />

      {showHFGuru && <HFGuruTile />}

      {renderDashboardContent()}

      {!showAwaitingRegistrationDashboard && (
        <>
          {isMobile && <TransactionsMobile queryLimit={20} withShowMore />}
          {!isMobile && <TableTransactions queryLimit={20} withShowMore />}
        </>
      )}
      {(isAddCurrency || isEditCurrency) && <AddCurrency />}
      <TransferPrebookPopups />
    </>
  );
};

export default withErrorBoundary(Dashboard);
