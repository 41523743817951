import axios from 'axios';
import { useEffect, useState, useCallback } from 'react';
import { ICurrency } from 'types';
import { getRate } from 'services/firebase/rates';
import { useStoreState } from 'state';

interface UseCurrencyRateParams {
  buyCurrency?: ICurrency['code'];
  sellCurrency?: ICurrency['code'];
  predicate?: boolean;
  withCancel?: boolean;
  /** Tries to use cached rate if available */
  useCachedRate?: boolean;
}

export interface UseCurrencyRateReturnValues {
  rate: number | null;
  isLoading: boolean;
  isError: boolean;
  conversionFeeRate: number;
  getRate: (
    sellCurrency: ICurrency['code'],
    buyCurrency: ICurrency['code'],
    cancelSimilarRequests?: boolean
  ) => Promise<number | undefined>;
}

const useCurrencyRate = ({
  buyCurrency,
  sellCurrency,
  predicate = true,
  withCancel = true,
  useCachedRate = false,
}: UseCurrencyRateParams): UseCurrencyRateReturnValues => {
  const [rate, setRate] = useState<number | null>(null);
  const [isError, setIsError] = useState(false);
  const [conversionFeeRate, setConversionFeeRate] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { getCachedRate } = useStoreState((state) => state.ReferenceDataState);

  const getRateHandler = useCallback(
    async (
      sellCurrencyCode: ICurrency['code'],
      buyCurrencyCode: ICurrency['code'],
      cancelSimilarRequests = true
    ) => {
      try {
        setRate(null);
        setConversionFeeRate(0);
        setIsLoading(true);

        if (useCachedRate) {
          const cachedRate = getCachedRate(sellCurrencyCode, buyCurrencyCode);

          if (cachedRate) {
            setRate(cachedRate.rate);
            setConversionFeeRate(cachedRate.conversionFeeRate);
            setIsLoading(false);

            return cachedRate.rate;
          }
        }

        const rateResponse = await getRate({
          sellCurrency: sellCurrencyCode,
          buyCurrency: buyCurrencyCode,
          withCancel: cancelSimilarRequests,
        });

        /** TODO: remove this condition when we will start returning
         **  correct status codes etc. from the server */
        if (rateResponse?.success && rateResponse.data) {
          setRate(rateResponse.data.rate);
          setConversionFeeRate(rateResponse.data.conversionFeeRate);

          setIsError(false);
          return rateResponse.data.rate;
        } else {
          setIsError(true);
          return undefined;
        }
      } catch (error: any) {
        if (axios.isCancel(error)) {
          console.warn('getRate request was canceled');
        } else {
          setIsError(true);
        }

        return undefined;
      } finally {
        setIsLoading(false);
      }
    },
    /*
      DO NOT add computed value getCachedRate to the dependency array,
      it will cause infinite re-renders. This should be fixed once we update state library and make
      sure computed values are not causing re-renders for no reason.
    */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [useCachedRate]
  );

  useEffect(() => {
    if (predicate && buyCurrency && sellCurrency) {
      getRateHandler(sellCurrency, buyCurrency, withCancel);
    }
  }, [buyCurrency, sellCurrency, getRateHandler, predicate, withCancel]);

  return {
    rate,
    isLoading,
    isError,
    conversionFeeRate,
    getRate: getRateHandler,
  };
};

export default useCurrencyRate;
