import { Dispatch, ReactNode, SetStateAction } from 'react';
import { Column } from 'react-table';
import {
  Nullable,
  IRecipient,
  IInvoiceFromSearch,
  IContact,
  PartialContact,
  TAccountingSystem,
} from 'types';
import { isReceivableInvoice, getInvoiceRemainingAmount } from 'utils/invoices';
import AmountCell from 'components/shared/AmountCell/AmountCell';
import AmountSellCurrencyCell from 'components/shared/AmountSellCurrencyCell/AmountSellCurrencyCell';
import ContactCellNew from 'components/shared/ContactCellNew/ContactCellNew';
import DateCell from 'components/shared/DateCell/DateCell';
import DirectionCell from 'components/shared/DirectionCell/DirectionCell';
import InvoiceNumberCell from 'components/shared/InvoiceNumberCell/InvoiceNumberCell';
import ProfitAndLossCell from '../ProfitAndLossCell/ProfitAndLossCell';
import { InvoiceFromSearchStatusCell } from './components/Cells/Cells';
import StatusIconCell from '../StatusIconCell/StatusIconCell';
import InvoiceActionsMenu from './components/InvoiceActionsMenu/InvoiceActionsMenu';
import DoActionCell from './components/DoActionCell/DoActionCell';
import InvoiceSyncedToExternalSourceCell from './components/InvoiceSyncedToExternalSourceCell/InvoiceSyncedToExternalSourceCell';

import Icon from '../Icon/Icon';
import { Row } from '../Row/Row';
import { mapAccountingIntegrationToIcon } from 'variables';
import { getPartialRecipientFromInvoiceFromSearch } from './utils';
import { Col } from '../Col/Col';
import { Paragraph, TextHint } from '../Typography/Typography';
import InvoiceDateCell from './components/InvoiceDateCell/InvoiceDateCell';
import { FlagCell } from '../FlagCell/FlagCell';
import { parseRateWithPrecision } from 'utils';

export const generateTableColumns = ({
  recipientById,
  setCancelPrebookInvoice,
  setContactForEdit,
  setExistingInvoiceTracking,
  setInvoicesForAllocateFx,
  setDeleteManualInvoice,
  setTransferIdToShowPaymentDetails,
  setRemoveExistingPrebookInvoice,
  setInvoiceDecide,
  setInvoiceForAddContact,
  setShowAllDecideFields,
  renderActionCell,
  tab,
  accountingIntegrationSystem,
  entityCurrencyCode,
  updateInMemoryInvoices,
  paymentRunId,
  removeInvoiceFromPaymentRun,
  isUpdatingPaymentRun,
  draftPaymentRunInvoiceIds,
  showDueDateCell,
  showDoActionCell,
}: {
  recipientById: (id: Nullable<string>) => IRecipient | undefined;
  setCancelPrebookInvoice: Dispatch<SetStateAction<IInvoiceFromSearch | null>>;
  setContactForEdit: Dispatch<SetStateAction<IContact | PartialContact | null>>;
  setExistingInvoiceTracking: Dispatch<
    SetStateAction<IInvoiceFromSearch | null>
  >;
  setInvoicesForAllocateFx: Dispatch<SetStateAction<IInvoiceFromSearch[]>>;
  setDeleteManualInvoice: Dispatch<SetStateAction<IInvoiceFromSearch | null>>;
  setTransferIdToShowPaymentDetails: Dispatch<SetStateAction<string | null>>;
  setRemoveExistingPrebookInvoice: Dispatch<
    SetStateAction<IInvoiceFromSearch | null>
  >;
  setInvoiceDecide: Dispatch<SetStateAction<IInvoiceFromSearch | null>>;
  setInvoiceForAddContact: Dispatch<SetStateAction<IInvoiceFromSearch | null>>;
  setShowAllDecideFields: Dispatch<SetStateAction<boolean>>;
  renderActionCell?: (record: IInvoiceFromSearch) => ReactNode;
  tab: string | null;
  accountingIntegrationSystem?: TAccountingSystem;
  entityCurrencyCode: Nullable<string>;
  updateInMemoryInvoices: (
    updateFunction: (invoices: IInvoiceFromSearch[]) => IInvoiceFromSearch[]
  ) => void;
  paymentRunId?: string;
  removeInvoiceFromPaymentRun?: (paymentRunInvoiceId: string) => Promise<void>;
  isUpdatingPaymentRun?: boolean;
  draftPaymentRunInvoiceIds?: string[];
  showDueDateCell?: boolean;
  showDoActionCell?: boolean;
}): Column<IInvoiceFromSearch>[] => {
  const getDateCells = (): Column<IInvoiceFromSearch>[] => {
    if (tab === 'paid') {
      return [
        {
          accessor: 'fullyPaidOnDate',
          Header: 'Payment Date',
          Cell: ({ value }) => <DateCell value={value} />,
          width: 100,
        },
      ];
    }
    if (showDueDateCell) {
      return [
        {
          accessor: 'dueDate',
          Header: 'Due',
          Cell: ({ value, row }) => (
            <InvoiceDateCell
              withOverdueMarker
              dueDate={value}
              plannedPaymentDate={row.original.plannedPaymentDate}
            />
          ),
          width: 100,
        },
      ];
    }

    return [
      {
        accessor: 'date',
        Header: 'Issued',
        Cell: ({ value }: any) => <DateCell value={value} />,
        width: 100,
      },
      {
        accessor: 'dueDate',
        Header: 'Due',
        Cell: ({ value, row }) => (
          <InvoiceDateCell
            withOverdueMarker
            dueDate={value}
            plannedPaymentDate={row.original.plannedPaymentDate}
          />
        ),
        width: 100,
      },
    ];
  };
  const pnlCells: Column<IInvoiceFromSearch>[] =
    tab !== 'paid'
      ? [
          {
            id: 'unrealisedPnl',
            accessor: 'profitAndLoss',
            Header: ({ rows }) => {
              const showAdditionalText = rows.some(
                ({ original }) => !!original.profitAndLoss
              );

              return (
                <Col>
                  <Paragraph variant="bold">
                    {entityCurrencyCode ?? ''} Value
                  </Paragraph>
                  {showAdditionalText && <TextHint>Unrealised PnL</TextHint>}
                </Col>
              );
            },
            disableSortBy: true,
            Cell: ({ row }) => (
              <Col alignItems="flex-start">
                <AmountSellCurrencyCell record={row.original} />
                <ProfitAndLossCell profitAndLoss={row.original.profitAndLoss} />
              </Col>
            ),
            width: 120,
          },
        ]
      : [
          {
            disableSortBy: true,
            accessor: 'transferRate',
            Header: 'FX Rate',
            Cell: ({ value }) => (
              <Paragraph>{parseRateWithPrecision(value)}</Paragraph>
            ),
            width: 100,
          },
          {
            id: 'realisedPnl',
            accessor: 'totalPnl',
            Header: () => {
              return (
                <Col>
                  <Paragraph variant="bold">
                    {entityCurrencyCode ?? ''} Value
                  </Paragraph>
                  <TextHint>Total PnL</TextHint>
                </Col>
              );
            },
            disableSortBy: true,
            Cell: ({
              row: {
                original: { totalAmountInEntityCurrency, totalPnl },
              },
            }) => {
              return (
                <Col>
                  {totalAmountInEntityCurrency && (
                    <AmountCell
                      amount={totalAmountInEntityCurrency}
                      currencyCode={entityCurrencyCode}
                    />
                  )}
                  <ProfitAndLossCell profitAndLoss={totalPnl ?? null} />
                </Col>
              );
            },
            width: 120,
          },
        ];

  const statusCell: Column<IInvoiceFromSearch>[] = [
    {
      accessor: 'status',
      Header: 'Status',
      Cell: ({ row }) => (
        <InvoiceFromSearchStatusCell
          invoice={row.original}
          draftPaymentRunInvoiceIds={draftPaymentRunInvoiceIds}
        />
      ),
      width: 150,
    },
  ];

  const actionCells: Column<IInvoiceFromSearch>[] = [
    {
      id: 'toDo',
      disableSortBy: true,
      Cell: ({ row }: any) =>
        renderActionCell ? (
          renderActionCell(row.original)
        ) : (
          <DoActionCell
            record={row.original}
            updateInMemoryInvoices={updateInMemoryInvoices}
            setContactForEdit={setContactForEdit}
            setInvoiceForAddContact={setInvoiceForAddContact}
            // only pass it when we are inside payment run tab, otherwise we do not want it even when we have paymentRunId
            paymentRunId={tab === 'paymentRun' ? paymentRunId : undefined}
            draftPaymentRunInvoiceIds={draftPaymentRunInvoiceIds}
          />
        ),
      width: 174,
    },
  ];

  const iconCells: Column<IInvoiceFromSearch>[] = [
    {
      id: 'icon',
      disableSortBy: true,
      Cell: ({ row }: any) => (
        <StatusIconCell
          trackingId={row.original.trackingId}
          contractId={row.original.contractId}
          prebookRate={row.original.prebookRate}
        />
      ),
      width: 48,
      minWidth: 48,
    },
  ];

  const invoiceSyncedToExternalSourceCell: Column<IInvoiceFromSearch>[] = [
    {
      id: 'invoiceSyncedToExternalSourceCell',
      Header: (
        <Row flex={1} justifyContent="center">
          {accountingIntegrationSystem && (
            <Icon
              width="20px"
              height="20px"
              icon={mapAccountingIntegrationToIcon[accountingIntegrationSystem]}
            />
          )}
        </Row>
      ),
      disableSortBy: true,
      Cell: ({ row }: any) => (
        <InvoiceSyncedToExternalSourceCell record={row.original} />
      ),
      width: 60,
    },
  ];

  return [
    {
      accessor: 'invoiceNumber',
      Header: 'Ref',
      Cell: ({ row }: any) => <InvoiceNumberCell record={row.original} />,
      width: 110,
    },
    {
      Header: () => (
        <Col>
          <Paragraph variant="bold">Recipient</Paragraph>
          <TextHint>Contact name</TextHint>
        </Col>
      ),
      accessor: 'contactRecipientName',
      Cell: ({ row }: any) => {
        const recipientToUse = getPartialRecipientFromInvoiceFromSearch({
          recipientById,
          invoice: row.original,
        });

        return (
          <ContactCellNew
            invoice={row.original}
            recipientToUse={recipientToUse}
            setContactForEdit={setContactForEdit}
            setInvoiceForAddContact={setInvoiceForAddContact}
            withReviewStatus={tab !== 'paid'}
          />
        );
      },
      width: 180,
    },
    ...getDateCells(),
    {
      accessor: 'currency',
      disableSortBy: true,
      Cell: ({ value }: any) => <FlagCell currencyCode={value} />,
      width: 50,
      minWidth: 50,
    },
    {
      accessor: tab === 'paid' ? 'amountPaid' : 'amountDue',
      Header: tab === 'paid' ? 'Paid Amount' : 'Amount Due',
      Cell: ({ row }: any) => (
        <>
          <DirectionCell
            withTitle={false}
            isReceivable={isReceivableInvoice(row.original)}
            currencyCode={row.original.currency}
          />
          <AmountCell
            size="large"
            amount={getInvoiceRemainingAmount(row.original)}
            currencyCode={row.original.currency}
          />
        </>
      ),
      filter: 'direction',
      width: 160,
    },
    ...(tab !== 'receivables' && tab !== 'received'
      ? [...pnlCells, ...statusCell]
      : []),
    ...(showDoActionCell ? actionCells : []),
    ...(tab !== 'paid' && tab !== 'received' ? iconCells : []),
    ...(tab === 'paid' && !!accountingIntegrationSystem
      ? invoiceSyncedToExternalSourceCell
      : []),
    {
      id: 'dots',
      Header: () => null,
      disableSortBy: true,
      Cell: ({ row }: any) => (
        <InvoiceActionsMenu
          record={row.original}
          setInvoiceDecide={setInvoiceDecide}
          setCancelPrebookInvoice={setCancelPrebookInvoice}
          setExistingInvoiceTracking={setExistingInvoiceTracking}
          setInvoicesForAllocateFx={setInvoicesForAllocateFx}
          setDeleteManualInvoice={setDeleteManualInvoice}
          setTransferIdToShowPaymentDetails={setTransferIdToShowPaymentDetails}
          setRemoveExistingPrebookInvoice={setRemoveExistingPrebookInvoice}
          setShowAllDecideFields={setShowAllDecideFields}
          updateInMemoryInvoices={updateInMemoryInvoices}
          removeInvoiceFromPaymentRun={removeInvoiceFromPaymentRun}
          isUpdatingPaymentRun={isUpdatingPaymentRun}
          // only pass it when we are inside payment run tab, otherwise we do not want it even when we have paymentRunId
          paymentRunId={tab === 'paymentRun' ? paymentRunId : undefined}
        />
      ),
      width: 60,
      minWidth: 55,
    },
  ];
};
