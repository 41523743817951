import styled, { css } from 'styled-components';
import { Col, Row } from 'components';
import {
  cardBaseCss,
  cardBaseSmallCss,
  expandableCardBaseContentCss,
  expandableCardBaseWrapperCss,
} from 'components/shared/CardBase/CardBase.styles';

export const FollowedCurrencyWrapper = styled.div<{
  loading: boolean;
}>(
  ({ loading }) => css`
    height: 186px;
    pointer-events: ${loading && 'none'};

    ${expandableCardBaseWrapperCss}
    ${cardBaseSmallCss}

    @media (min-width: ${({ theme }) => theme.breakpoint.largeMin}px) {
      height: 136px;
      ${cardBaseCss}
    }
  `
);

export const FollowedCurrencyContentWrapper = styled(Col)`
  padding: 8px 12px;
  border-radius: 8px;
  background: ${({ theme }) => theme.color.white};

  ${expandableCardBaseContentCss}
`;

export const RateWrapper = styled(Row)`
  column-gap: ${({ theme }) => theme.spacing.m};
`;

export const HexagonsWrapper = styled(Row)`
  flex: 1;
  align-self: center;
  column-gap: ${({ theme }) => theme.spacing.xs};
  @media (min-width: ${({ theme }) => theme.breakpoint.largeMin}px) {
    column-gap: ${({ theme }) => theme.spacing.m};
  }
`;
