import { FC, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { Col, FundingAccountCredentials, Loader, Row, Title } from 'components';
import Popup from 'components/shared/Popup/Popup';
import useUrlValues from 'hooks/useUrlValues';
import { subscribeToConversionById } from 'services/firebase/conversions';
import { IConversion, CONVERSION_STATUS } from 'types';
import ExchangeDetails from './components/ExchangeDetails/ExchangeDetails';
import useFundingAccountCredentialsActions from '../FundingAccountCredentials/useFundingAccountCredentialsActions';
import FundingAccountCredentialsActions from '../FundingAccountCredentialsActions/FundingAccountCredentialsActions';
import useBalance from 'hooks/useBalance';
import Button from '../Button/Button';

interface IOwnProps {
  conversionId?: string;
  onClose?: () => void;
}

const ConversionInner: FC<IOwnProps> = ({
  conversionId: propsConversionId,
  onClose: propsOnClose,
}) => {
  const theme = useTheme();
  const { conversionId: queryConversionId, setUrlValue } = useUrlValues(
    'conversionId'
  );
  const [isLoading, setIsLoading] = useState(true);
  const [
    selectedConversion,
    setSelectedConversion,
  ] = useState<IConversion | null>(null);
  const fundingAccountCredentialsActions = useFundingAccountCredentialsActions();
  const {
    isLoading: isLoadingPayWithBalance,
    isEnoughBalance,
    onPayWithBalance,
  } = useBalance({
    sellCurrency: selectedConversion?.sellCurrency,
    sellAmount: selectedConversion?.sellAmount,
  });

  const isAwaitingPayment =
    selectedConversion?.status === CONVERSION_STATUS.awaitingPayment;

  const canBePaidWithBalance = isEnoughBalance && isAwaitingPayment;
  const canBeCancelled =
    selectedConversion?.status === CONVERSION_STATUS.awaitingPayment ||
    selectedConversion?.status === CONVERSION_STATUS.funded;
  const conversionId = propsConversionId || queryConversionId;

  useEffect(() => {
    let unsubscribe: () => void;

    if (conversionId) {
      unsubscribe = subscribeToConversionById({
        conversionId: conversionId,
        callback: (data) => {
          setSelectedConversion(data);
          setIsLoading(false);
        },
      });
    }

    return () => unsubscribe?.();
  }, [conversionId]);

  const onClose = () => {
    setUrlValue({ conversionId: '' });
    propsOnClose?.();
  };

  const onCancel = () => {
    if (selectedConversion) {
      setUrlValue({
        cancelContractId: selectedConversion.id,
        cancelType: 'conversion',
      });
    }
  };

  if (!selectedConversion) {
    return null;
  }

  const onPayWithBalanceHandler = () =>
    onPayWithBalance({
      payload: {
        currency: selectedConversion.sellCurrency,
        amount: selectedConversion.sellAmount,
      },
    });

  return (
    <Popup
      HeaderContent={<Title variant="h3">FX Exchange</Title>}
      FooterContent={
        <Row gap={theme.spacing.m} flex={1}>
          <FundingAccountCredentialsActions
            isFundingInitiated={!isAwaitingPayment}
            isLoading={isLoading || isLoadingPayWithBalance}
            onPayWithBalanceHandler={onPayWithBalanceHandler}
            fundingAccountCredentialsActions={fundingAccountCredentialsActions}
            onSecondaryButtonClick={onClose}
            secondaryButtonText="Close"
          />
          {canBeCancelled && (
            <Button variant="secondary" onClick={onCancel}>
              Cancel
            </Button>
          )}
        </Row>
      }
      width="900px"
      height="800px"
      onClose={onClose}
    >
      {isLoading && <Loader size="large" />}
      {!isLoading && (
        <Col rowGap={theme.spacing.xl}>
          <ExchangeDetails selectedConversion={selectedConversion} />

          {isAwaitingPayment && (
            <FundingAccountCredentials
              assetId={selectedConversion.id}
              canBePaidWithBalance={canBePaidWithBalance}
              sellCurrency={selectedConversion.sellCurrency}
              fundingAccountCredentialsActions={
                fundingAccountCredentialsActions
              }
            />
          )}
        </Col>
      )}
    </Popup>
  );
};

export default ConversionInner;
