import { useState, useEffect } from 'react';
import { getHedgeRecommendationsV2 } from 'services/firebase/analysis';
import { IForwardRateAndFees } from 'types/rates';
import { Notify } from 'utils';

export type IHedgeRecommendationPeriodTypes = 'week' | 'month';

export type IHedgeRecommendationResultV2 = {
  period: number;
  buyCurrency: string;
  sellCurrency: string;
  currency: string;
  /** In future this may also include week */
  periodType: IHedgeRecommendationPeriodTypes;
  /** To be displayed by UI */
  periodAsText: string;
  from: string;
  expiryDate: string;
  newHedgeAmount: number;
  expectedCashFlowsAmount: number;
  existingHedgeAmount: number;
  newHedgeRatio: number;
  message?: string;
  currencyRating: number;
  minHedgeRatio: number;
  maxHedgeRatio: number;
  forwardCurrencyRateWins: boolean;
  forwardRatesPerCurrencyPair: IForwardRateAndFees;
  currencyTargets?: Record<string, number>;
  weightedAverageRatio: number;
};

/**
 * API response with IHedgeRecommendationResult per currency
 */
export type IHedgeRecommendationResultsV2 = Record<
  string,
  IHedgeRecommendationResultV2[]
>;

export const useGetHedgeRecommendations = ({
  calendarInterval,
  numberOfRecommendations,
}: {
  calendarInterval: 'month' | 'week';
  numberOfRecommendations: number;
}) => {
  const [
    isLoadingRecommendations,
    setIsLoadingRecommendations,
  ] = useState<boolean>(false);
  const [
    recommendations,
    setRecommendations,
  ] = useState<IHedgeRecommendationResultsV2>();

  useEffect(() => {
    const fetchRecommendations = async () => {
      setIsLoadingRecommendations(true);
      try {
        const results = await getHedgeRecommendationsV2({
          calendarInterval,
          timePeriods: numberOfRecommendations,
        });
        if (!!results?.data?.data) {
          setRecommendations(results.data.data);
        }
      } catch (error: unknown) {
        if (error instanceof Error) {
          Notify.error(error.message);
        } else if (typeof error === 'string') {
          Notify.error(error);
        } else {
          Notify.error('An error occurred');
        }
      } finally {
        setIsLoadingRecommendations(false);
      }
    };

    fetchRecommendations();
  }, [calendarInterval, numberOfRecommendations, setRecommendations]);

  return { isLoadingRecommendations, recommendations, setRecommendations };
};
