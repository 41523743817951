import { action, computed, thunk } from 'easy-peasy';

import { Firebase } from 'services';
import {
  refreshCashflowFromCsv,
  uploadFileWithCustomCashflows,
} from 'services/cashflows';
import {
  getAllCachedRates,
  hedgeCashflows,
  hedgeCashflowsByIds,
} from 'services/firebase';
import {
  refreshCashflowRisks,
  subscribeToCashflowsRisks,
} from 'services/firebase/analysis';
import { SWIFT_TYPE } from 'types';
import { Notify } from 'utils';
import { errorHandler } from 'utils/errors';
import { ReferenceDataStateModel } from './types';
import dayjs from 'dayjs';

export const ReferenceDataState: ReferenceDataStateModel = {
  isRefreshingCashflowsRisksData: false,
  showSpinnerInRecalculateButton: false,
  xeroImportPeriodData: [],
  nonTradingDays: [],
  countries: [],
  fees: [],
  naturesOfBusiness: [],
  fundingAccounts: [],
  riskContribution: {},
  cashflowsRisks: null,
  isCashflowsRisksLoaded: false,
  systemVariables: null,
  rateAnalyses: null,
  potentialSavings: null,
  potentialSavingsWithDate: null,
  ratings: null,
  allowLogin: computed((state) =>
    state.systemVariables?.maintenance?.allowLogin !== undefined
      ? state.systemVariables.maintenance.allowLogin
      : true
  ),
  setState: action((state, payload) => {
    const [prop, to] = payload;
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    state[prop] = to;
  }),
  setShowSpinnerInRecalculateButton: action((state, payload) => {
    state.showSpinnerInRecalculateButton = payload;
  }),
  getNonTradingDays: thunk(async (actions, params) => {
    const data = await Firebase.getNonTradingDays(params);

    if (data) {
      actions.setState(['nonTradingDays', data.data]);
    }
  }),
  getNaturesOfBusiness: thunk(async (actions) => {
    const data = await Firebase.getNatureOfBusiness();

    if (data) {
      actions.setState(['naturesOfBusiness', data.values]);
    }
  }),
  getSystemVariables: thunk(async (actions) => {
    const data = await Firebase.getSystemVariables();

    if (data) {
      actions.setState(['systemVariables', data]);
    }
  }),
  getCountries: thunk(async (actions) => {
    const data = await Firebase.getCountries();

    if (data) {
      actions.setState(['countries', data]);
    }
  }),
  getSwiftFees: thunk(async (actions) => {
    try {
      const data = await Firebase.getSwiftFees();

      if (data) {
        actions.setState(['fees', data]);
      }
    } catch (error: any) {
      errorHandler(error);
    }
  }),
  countryByCode: computed(
    [(state) => state.countries],
    (countries) => (countryCode) =>
      countries.find(
        (item) => item.alpha2.toLowerCase() === countryCode?.toLowerCase()
      ) || null
  ),
  feesByCurrencyCode: computed(
    [(state) => state.fees],
    (fees) => (currencyCode) =>
      fees.find((item) => item.id === currencyCode) || null
  ),
  getSwiftShared: computed((state) => (currencyCode) =>
    state.feesByCurrencyCode(currencyCode)?.swiftShared || null
  ),
  getSwiftOurs: computed((state) => (currencyCode, country) => {
    if (currencyCode && country) {
      return (
        state.feesByCurrencyCode(currencyCode)?.swiftOurs?.[
          country.toUpperCase()
        ] || null
      );
    }

    return null;
  }),
  getPriorityFee: computed((state) => (type, currencyCode, country) =>
    type === SWIFT_TYPE.ours
      ? state.getSwiftOurs(currencyCode, country)
      : state.getSwiftShared(currencyCode)
  ),
  getRateAnalyses: thunk(async (actions, payload) => {
    const data = await Firebase.getRateAnalysis(payload);

    if (data) {
      actions.setState(['rateAnalyses', data]);
    }
  }),

  getPotentialSavings: thunk(async (actions, payload) => {
    const data = await Firebase.getPotentialSavings(payload);

    if (data) {
      actions.setState(['potentialSavings', data]);
    }
  }),
  getPotentialSavingsWithDate: thunk(async (actions, payload) => {
    const data = await Firebase.getPotentialSavingsWithDate(payload);

    if (data) {
      actions.setState(['potentialSavingsWithDate', data]);
    }
  }),
  getRatings: thunk(async (actions, payload) => {
    const data = await Firebase.getRatings(payload);

    if (data) {
      actions.setState(['ratings', data]);
    }
  }),
  getFundingAccounts: thunk(async (actions, payload) => {
    const data = await Firebase.getFundingAccounts(payload);

    if (data) {
      actions.setState(['fundingAccounts', data.data.data]);
    }
  }),
  fundingAccountByCurrency: computed(
    [(state) => state.fundingAccounts],
    (fundingAccounts) => (ccCode) =>
      fundingAccounts.find((fundingAccount) =>
        fundingAccount.currencies.includes(ccCode)
      ) ||
      fundingAccounts.find((fundingAccount) =>
        fundingAccount.currencies.includes('*')
      )
  ),
  subscribeToCashflowsRisks: thunk(({ setState }, payload) => {
    const subscriber = subscribeToCashflowsRisks({
      ...payload,
      callback: (cashflowRisks) => {
        setState(['cashflowsRisks', cashflowRisks]);
        setState(['isCashflowsRisksLoaded', true]);
      },
    });

    return subscriber;
  }),
  refreshCashflowsRisksData: thunk(
    async ({ setState }, payload, { getState, getStoreState }) => {
      try {
        const { entityId, sellCurrency, files } = payload;
        const { isRefreshingCashflowsRisksData } = getState();
        const storeState = getStoreState();
        const { entityCashflowsSettings } = storeState.EntityState;
        const { userEntity } = storeState.UserState;

        if (isRefreshingCashflowsRisksData) {
          return;
        }

        setState(['isRefreshingCashflowsRisksData', true]);

        /** @todo: quick fix, consider moving to the BE at some point */
        if (
          entityCashflowsSettings?.fileShareSystem === 'microsoftFileUpload' &&
          files?.length
        ) {
          const formData = new FormData();
          formData.append('file', files[0].file);
          await uploadFileWithCustomCashflows(formData);
        }

        /** for microsoft there is no sense of refreshing, because data is always the same until new file upload */
        if (
          entityCashflowsSettings?.fileShareUrl &&
          entityCashflowsSettings?.fileShareSystem === 'google'
        ) {
          /** @todo: quick fix, consider moving to the BE at some point */
          await refreshCashflowFromCsv();
        }

        const now = dayjs();

        if (
          userEntity.packages?.fxManagement &&
          (dayjs(userEntity.packages.fxManagement.enabledUntil).isAfter(now) ||
            dayjs(userEntity.packages.fxManagement.freeTrialUntil).isAfter(now))
        ) {
          await refreshCashflowRisks({
            entityId,
            sellCurrency,
          });
        }
      } catch (error: any) {
        errorHandler(error);
      } finally {
        setState(['isRefreshingCashflowsRisksData', false]);
      }
    }
  ),
  prebookAll: thunk(async (_, payload) => {
    try {
      await hedgeCashflows(payload);
    } catch (error: any) {
      errorHandler(error);
    }
  }),
  prebookAllByIds: thunk(async (_, payload) => {
    try {
      const { data } = await hedgeCashflowsByIds(payload);

      if (data.success) {
        const failureCount = data?.data?.failures?.length || 0;
        const successCount = data?.data?.successes?.length || 0;
        const totalCount = failureCount + successCount;

        if (failureCount > 0) {
          Notify.info(
            `We were able to prebook successfully for ${successCount} invoices out of ${totalCount}.${' '}
            ${failureCount} invoices unsuccessful. You can try again. If the issue persists, please contact support.`
          );
        } else {
          Notify.success(
            'A prebooking has been applied to all selected invoices'
          );
        }
      } else {
        Notify.error(`The action was unsuccessful. Error ${data?.message}`);
      }
    } catch (error: any) {
      Notify.error(error?.message);
    }
  }),
  getRiskContribution: thunk(async (actions) => {
    const data = await Firebase.getRiskContribution();

    if (data) {
      actions.setState(['riskContribution', data]);
    }
  }),
  getAllCachedRates: thunk(async (actions) => {
    const data = await getAllCachedRates();

    if (data.success && data.data) {
      actions.setState(['cachedRates', data.data]);
    }
  }),
  cachedRates: [],
  getCachedRate: computed(
    [(state) => state.cachedRates],
    (cachedRates) => (sellCurrency, buyCurrency) =>
      cachedRates.find(
        (rate) => rate.currencyPair === `${sellCurrency}${buyCurrency}`
      )
  ),
};
