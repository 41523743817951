import { Paragraph, Row } from 'components';
import NumberCircle from 'components/shared/NumberCircle/NumberCircle';
import { useTheme } from 'styled-components';
import { IBulkPayment } from 'types';

const TransfersCell: React.FC<{
  bulkPayment: IBulkPayment;
}> = ({ bulkPayment }) => {
  const theme = useTheme();

  return (
    <Row gap={theme.spacing.xs}>
      {!!bulkPayment.localCount && (
        <Row columnGap={theme.spacing.xs}>
          <Paragraph>Local</Paragraph>
          <NumberCircle value={bulkPayment.localCount} />
        </Row>
      )}
      {!!bulkPayment.swiftCount && (
        <Row columnGap={theme.spacing.xs}>
          <Paragraph>SWIFT</Paragraph>
          <NumberCircle value={bulkPayment.swiftCount} />
        </Row>
      )}
    </Row>
  );
};

export default TransfersCell;
