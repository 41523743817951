import { action, thunk } from 'easy-peasy';
import { getLimitOrders, getLimitOrderById } from 'services/firebase';
import { errorHandler } from 'utils/errors';
import { LimitOrdersStateModel } from './types';

export const LimitOrdersState: LimitOrdersStateModel = {
  limitOrders: [],
  isLimitOrdersLoading: true,
  setState: action((state, payload) => {
    const [prop, to] = payload;
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type
    state[prop] = to;
  }),
  getLimitOrders: thunk(async ({ setState }, payload) => {
    try {
      setState(['isLimitOrdersLoading', true]);
      const { data } = await getLimitOrders(payload);

      if (data?.success) {
        setState(['limitOrders', data.data]);
      } else {
        errorHandler(data);
      }
      setState(['isLimitOrdersLoading', false]);
    } catch (error) {
      errorHandler(error);
      setState(['isLimitOrdersLoading', false]);
    }
  }),
  getLimitOrderById: thunk(async ({ setState }, payload) => {
    try {
      const { data } = await getLimitOrderById(payload);

      if (data?.success) {
        return data.data;
      } else {
        errorHandler(data);
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setState(['isLimitOrdersLoading', false]);
    }
  }),
};
