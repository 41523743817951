import { FC, ReactNode, ReactText } from 'react';
import Icon from '../Icon/Icon';
import { InfoBoxText, InfoBoxWrapper } from './InfoBox.styles';

interface IOwnProps {
  text: ReactNode | ReactText;
  icon?: string;
}

const InfoBox: FC<IOwnProps> = ({ text, icon }) => (
  <InfoBoxWrapper>
    {icon && <Icon icon={icon} />}
    <InfoBoxText>{text}</InfoBoxText>
  </InfoBoxWrapper>
);

export default InfoBox;
