import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import Tabs from './components/Tabs/Tabs';
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import utcPlugin from 'dayjs/plugin/utc';
import TransferPrebookPopups from 'components/shared/TransferPrebookPopups/TransferPrebookPopups';
import { CASHFLOW_TYPE_NEW, ICashflowFromSearch } from 'services/cashflows';
import ContributorsTable from './components/ContributorsTable/ContributorsTable';

dayjs.extend(quarterOfYear);
dayjs.extend(utcPlugin);

interface IOwnProps {
  data: ICashflowFromSearch[];
  onGoBack: () => void;
}

const DateRangeInfo: FC<IOwnProps> = ({ data, onGoBack }) => {
  const [cashflows, setCashflows] = useState<ICashflowFromSearch[]>(data);
  const [filter, setFilter] = useState<CASHFLOW_TYPE_NEW | undefined>();

  useEffect(() => {
    setCashflows(data);
  }, [data]);

  const updateInMemoryCashflows = useCallback(
    (
      updateFunction: (
        cashflows: ICashflowFromSearch[]
      ) => ICashflowFromSearch[]
    ) => {
      setCashflows((prevState) => updateFunction(prevState));
    },
    []
  );

  const filteredCashflows = useMemo(() => {
    if (filter && Object.values(CASHFLOW_TYPE_NEW).includes(filter)) {
      return cashflows.filter((cashflow) => cashflow.type === filter);
    }

    return cashflows;
  }, [cashflows, filter]);

  return (
    <>
      <Tabs filter={filter} setFilter={setFilter} onGoBack={onGoBack} />

      <ContributorsTable
        isVirtualized
        data={filteredCashflows}
        itemsCount={filteredCashflows.length}
        updateInMemoryCashflows={updateInMemoryCashflows}
      />

      <TransferPrebookPopups />
    </>
  );
};

export default DateRangeInfo;
