import { Dispatch, FC, SetStateAction, useEffect } from 'react';
import Card from 'components/shared/Card/Card.styles';
import { Row, Loader, StaleInputSelect } from 'components';
import useUrlValues from 'hooks/useUrlValues';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { StepperItem } from 'components/shared/StaleStepsHorizontal/StaleStepsHorizontal.styles';
import { TRisksDataPeriod } from 'pages/Risks/types';
import { IRiskGroupedByEntityData } from 'services/firebase/analysis';
import ChartCashflows from 'components/shared/ChartCashflows/ChartCashflows';

dayjs.extend(advancedFormat);

interface IOwnProps {
  cashflowsRisksData: IRiskGroupedByEntityData['perPeriod'];
  isLoadingCashflowsRisksData: boolean;
  setActiveDateRange: Dispatch<SetStateAction<string | undefined>>;
}

const MonthlyBreakdown: FC<IOwnProps> = ({
  cashflowsRisksData,
  isLoadingCashflowsRisksData,
  setActiveDateRange,
}) => {
  const { setUrlValue, period, currency } = useUrlValues('currency', 'period');
  useEffect(() => {
    if (!period) {
      setUrlValue({ period: 'month' });
    }
  }, [period, setUrlValue]);

  const onChartPeriodClick = (periodFromChart: string) => {
    setActiveDateRange(periodFromChart);
  };

  const onSelectPeriod = ({ value }: { value: TRisksDataPeriod }) => {
    setUrlValue({ period: value });
    setActiveDateRange(undefined);
  };

  return (
    <>
      <Card
        alignSelf="stretch"
        justifyContent="space-between"
        flex={1}
        position="relative"
      >
        <Row alignItems="flex-start">
          <Row justifyContent="flex-start">
            <StepperItem current>Cashflows</StepperItem>
          </Row>
          <StaleInputSelect
            selected={period}
            data={[
              {
                id: 'week',
                name: 'Weekly',
                value: 'week',
              },
              {
                id: 'month',
                name: 'Monthly',
                value: 'month',
              },
              {
                id: 'quarter',
                name: 'Quarterly',
                value: 'quarter',
              },
            ]}
            onSelect={onSelectPeriod}
            id="risks-data-duration"
          />
        </Row>

        <Row flex={1} alignItems="unset">
          {isLoadingCashflowsRisksData && <Loader size="large" />}
          {!isLoadingCashflowsRisksData && (
            <ChartCashflows
              data={cashflowsRisksData}
              currencyCode={currency}
              period={period as TRisksDataPeriod}
              onChartClick={onChartPeriodClick}
            />
          )}
        </Row>
      </Card>
    </>
  );
};

export default MonthlyBreakdown;
