import { ActionButton } from 'components/shared/ActionButton/ActionButton.styles';
import InlineLoader from 'components/shared/InlineLoader/InlineLoader';
import useBalance from 'hooks/useBalance';
import { useStoreState } from 'state';
import { approveBulkPayment } from 'services/firebase';
import { BULK_PAYMENT_STATUS, IBulkPayment } from 'types';
import { Notify } from 'utils';
import { isBulkPaymentApprovableByUser } from 'utils/bulkPayments';
import { useState } from 'react';

interface IOwnProps {
  bulkPayment: IBulkPayment;
  onRowClick: (bulkPayment: IBulkPayment) => void;
}

export const InlineActions: React.FC<IOwnProps> = ({
  bulkPayment,
  onRowClick,
}) => {
  const { userId } = useStoreState(({ UserState }) => UserState);
  const [approvedByThisUser, setApprovedByThisUser] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const {
    isLoading: isLoadingBalance,
    isEnoughBalance,
    onPayWithBalance,
  } = useBalance({
    sellCurrency: bulkPayment?.sellCurrency,
    sellAmount: bulkPayment?.payAmount,
  });

  if (bulkPayment.status === BULK_PAYMENT_STATUS.cancelled) {
    return null;
  }

  if (
    bulkPayment.approval?.status === 'submitted' &&
    userId !== null &&
    userId !== undefined
  ) {
    const approvableByThisUser = isBulkPaymentApprovableByUser(
      bulkPayment,
      userId
    );

    const onApprove = async (event: any) => {
      event.stopPropagation();
      try {
        setIsApproving(true);
        const result = await approveBulkPayment({
          bulkPaymentId: bulkPayment.id,
          userId: userId,
        });

        if (result.data.success) {
          Notify.success('Bulk payment approved');
          setApprovedByThisUser(true);
        }
      } catch (error: any) {
        Notify.error(error?.message || 'Something went wrong approving');
      } finally {
        setIsApproving(false);
      }
    };

    return (
      <ActionButton
        disabled={!approvableByThisUser || approvedByThisUser || isApproving}
        onClick={onApprove}
      >
        Approve {isApproving && <InlineLoader ml />}
      </ActionButton>
    );
  }

  if (bulkPayment.status === BULK_PAYMENT_STATUS.awaitingPayment) {
    const onClick = (event: any) => {
      event.stopPropagation();
      if (isEnoughBalance) {
        onPayWithBalance({
          payload: {
            currency: bulkPayment.sellCurrency,
            amount: bulkPayment.payAmount,
          },
          onSuccess: () => {
            Notify.success('Funding successful');
          },
          onError: () => {
            onRowClick(bulkPayment);
          },
        });
      } else {
        onRowClick(bulkPayment);
      }
    };

    return (
      <ActionButton onClick={onClick} disabled={isLoadingBalance}>
        Fund {isLoadingBalance && <InlineLoader ml />}
      </ActionButton>
    );
  }

  return null;
};
