import { Loader, Paragraph } from 'components';
import { IHedgeRecommendationResultV2 } from 'pages/HedgeRecommendationsV2/useGetHedgeRecommendationsV2';
import { FC } from 'react';
import { parseRateWithPrecision } from 'utils';

interface IOwnProps {
  record: IHedgeRecommendationResultV2;
}

const IndicativeRateCell: FC<IOwnProps> = ({ record }) => {
  const rate = record.forwardRatesPerCurrencyPair?.rate;
  if (!rate) {
    return <Loader size="small" />;
  }

  return <Paragraph>{parseRateWithPrecision(rate)}</Paragraph>;
};

export default IndicativeRateCell;
