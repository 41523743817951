import { ISelectItem } from 'components/shared/StaleInputSelect/StaleInputSelect';
import {
  ENTITY_TYPE,
  IAvailableIntegration,
  INTEGRATION_TYPE,
  TAccountingSystem,
} from 'types';

export const BUSINESS_TYPES = [
  {
    id: 'soleTrader',
    name: 'Sole Trader',
    value: ENTITY_TYPE.soleTrader,
  },
  {
    id: 'limitedCompany',
    name: 'Limited Company (LTD)',
    value: ENTITY_TYPE.limitedCompany,
  },
  {
    id: 'PLC',
    name: 'Public Limited Company (PLC)',
    value: ENTITY_TYPE.publicLimitedCompany,
  },
  {
    id: 'simplePartnership',
    name: 'Simple Partnership (coming soon)',
    value: ENTITY_TYPE.simplePartnership,
    disabled: true,
  },
  {
    id: 'limitedLiabilityPartnership',
    name: 'Limited Liability Partnership (LLP) (coming soon)',
    value: ENTITY_TYPE.limitedLiabilityPartnership,
    disabled: true,
  },
];

export const SLIDER_DATA = [
  {
    id: 0,
    title: 'Manage your cashflows',
    text: 'See and manage all your cash and currency needs in one place.',
    icon: 'prebook',
  },
  {
    id: 1,
    title: 'Manage currency risks',
    text:
      'Make sound currency decisions with timely visibility and robust decision-making.',
    icon: 'rates',
  },
  {
    id: 2,
    title: 'Streamline FX & payments',
    text:
      'Accurate & effortless payment automation for domestic and international invoices.',
    icon: 'transfer',
  },
];

export const SLIDER_OPTIONS = {
  speed: 400,
  slidesToShow: 1,
  fade: true,
  arrows: false,
  dots: true,
  autoplay: true,
  autoplaySpeed: 3000,
};

// in minutes
export const DEFAULT_SESSION_TIMEOUT = 20;

export const RECIPIENT_ROUTING_TYPES = {
  aba: 'ABA',
  sortCode: 'Sort code',
  bsbCode: 'BSB code',
  institutionNo: 'Institution number',
  bankCode: 'Bank code',
  branchCode: 'Branch code',
  clabe: 'CLABE',
  cnaps: 'CNAPS',
};

export const VALIDATION_MESSAGES = [
  {
    id: 5,
    text: 'No more than 50 characters',
    typeId: 'maxLength',
    validate: (value: string) => value?.length <= 50,
  },
  {
    id: 0,
    text: 'At least 8 characters',
    typeId: 'minLength',
    validate: (value: string) => value?.length >= 8,
  },
  {
    id: 1,
    text: 'At least one number',
    typeId: 'oneNumber',
    validate: (value: string) => /(?=.*\d)/.test(value),
  },
  {
    id: 2,
    text: 'At least one upper case character',
    typeId: 'oneUppercase',
    validate: (value: string) => /(?=.*[A-Z])/.test(value),
  },
  {
    id: 3,
    text: 'At least one lower case character',
    typeId: 'oneLowercase',
    validate: (value: string) => value && /(?=.*[a-z])/.test(value),
  },
  {
    id: 4,
    text: 'At least one special character',
    typeId: 'oneSpecial',
    validate: (value: string) => /(?=.*[-+_!@#$%^&*.,?])/.test(value),
  },
];

export const ERROR_MESSAGES = {
  amountTooLow: 'Amount is too low',
  aboveCreditLimit:
    'Amount is too high. To increase your limit, please contact support.',
  requiredField: 'This field is required',
  incorrectDate: 'Date is incorrect',
  abovePrebookedAmount:
    'Exceeds prebooked amount. Reduce the amount, select a different prebooking, or use market rate.',
  incorrectEmail: 'Email is incorrect',
  incorrectFirstNameAndLastName:
    'First name and last name are incorrect. Please use only letters and divide them with a space.',
  noEntityId:
    'Unexpected error: No entity ID found. If this error persists then please contact support.',
  noSystem: 'No system found',
  noChallengeReturned:
    'No challenge returned. Unable to continue. Please try again. If this error persists then please contact support.',
  chooseApprovers: 'Choose approver(s)',
  chooseRecipients: 'Choose recipient(s)',
  noSameApprovers:
    'The same approver(s) cannot be included in both approval lists.',
  somethingWentWrong: (x?: string) => {
    if (!x) {
      return 'Something went wrong. Please try again. If this error persists then please contact support.';
    }

    return `Something went wrong ${x.trim()}. Please try again. If this error persists then please contact support.`;
  },
};

export const HELP_URL = 'https://help.hedgeflows.com';
export const SCAM_HELP_URL =
  'https://help.hedgeflows.com/how-to-avoid-fraudulent-payments';
export const SCHEDULE_MEET_URL = 'https://meetings.hubspot.com/alex2020';
export const SCHEDULE_ACCOUNTANT_MEET_URL =
  'https://meetings.hubspot.com/alex2020/accountants-demo';
export const PAYMENT_RUNS_DEMO_URL = 'https://youtu.be/Cv54gsCrW0M';
export const APPROVAL_PROCESS_DEMO_URL = 'https://youtu.be/zPje79U4BXI';
export const PREBOOKING_FX_DEMO_URL =
  'https://www.youtube.com/watch?v=DLRDBi9Lp-Q';
export const BOOK_A_DEMO_URL = 'https://www.hedgeflows.com/book-a-demo';

export const DATE_FORMAT = 'DD MM YYYY';
export const DATE_FORMAT_SHORT_MONTH_NAME = 'DD MMM YYYY';
export const DB_DATE_FORMAT = 'YYYY-MM-DD';
export const DB_MONTH_FORMAT = 'YYYY-MM';
export const MONTH_DATE_FORMAT = 'MMM YYYY';
export const DAY_MONTH_DATE_FORMAT = 'D MMM YYYY';
export const QUARTER_DATE_FORMAT = '[Q]Q YYYY';
export const TIME_FORMAT = 'HH:mm';

export const RISK_APPETITE_SLIDER_DATA = [
  {
    id: 1,
    title: 'Risk tolerance',
    text:
      'We help you set up the risk tolerance metric and monitor your currency risks so that you don’t lose more that you can afford to',
    icon: 'past-performance',
  },
  {
    id: 2,
    title: 'Risk preferences',
    text:
      'There is more than one way to think about risks and you can adopt approach that suits your business',
    icon: 'transfer',
  },
  {
    id: 3,
    title: 'Risk planning',
    text:
      'If you care about your profit margins and cashflows, take time to understand how currencies affect them.',
    icon: 'prebook',
  },
];

export const COMMITTED_CURRENCY_QUESTIONS: ISelectItem[] = [
  {
    id: 'COMMITTED_1',
    value: 'WaitUntilFXRatesReachTarget',
    name: 'Wait until FX rates reach my targets',
  },
  {
    id: 'COMMITTED_2',
    value: 'SecureRatesWhenGood',
    name: 'Secure rates when they are good',
  },
  {
    id: 'COMMITTED_3',
    value: 'SecureRatesWhenExposureReachesLimit',
    name: 'Secure rates when exposure reaches our risk limit',
  },
  {
    id: 'COMMITTED_4',
    value: 'SecureRatesWhenGettingInvoices',
    name: 'Secure rates as soon as I get foreign invoices',
  },
];

export const FUTURE_CURRENCY_QUESTIONS: ISelectItem[] = [
  {
    id: 'FUTURE_1',
    value: 'NoVisibility',
    name: 'I have no visibility on future cashflows',
  },
  {
    id: 'FUTURE_2',
    value: 'DoNothing',
    name: 'Do nothing until invoices are accrued',
  },
  {
    id: 'FUTURE_3',
    value: 'SecureRatesWhenGood',
    name: 'Secure rates when they are good',
  },
  {
    id: 'FUTURE_4',
    value: 'SecureRatesToStayWithinRiskLimits',
    name: 'Secure rates to stay within my risk limit',
  },
];

export const mapAccountingIntegrationToIcon: Record<
  TAccountingSystem,
  string
> = {
  xero: 'xero-ico',
  quickbooks: 'quick-books-ico',
  dynamics: 'dynamics-365-ico',
  netsuite: 'netsuite-ico',
};

export const LOOKUP = [
  { value: 1e9, symbol: 'B', digits: 1 },
  { value: 1e6, symbol: 'M', digits: 1 },
  { value: 1e3, symbol: 'K', digits: 1 },
  { value: 1, symbol: '', digits: 0 },
];

export const INTEGRATIONS_DATA: {
  [idx: string]: IAvailableIntegration[];
} = {
  accounting: [
    {
      id: 'xero',
      name: 'Xero',
      system: 'xero',
      img: '#xero-ico',
      available: true,
      type: INTEGRATION_TYPE.accounting,
    },
    {
      id: 'dynamics',
      system: 'dynamics',
      name: 'Dynamics 365 Business Central',
      platformNames: ['Dynamics 365 Business Central'],
      img: '#dynamics-365-ico',
      available: true,
      type: INTEGRATION_TYPE.accounting,
    },
    {
      id: 'quickbooks',
      name: 'QuickBooks',
      system: 'quickbooks',
      platformNames: ['QuickBooks Online'],
      img: '#quick-books-ico',
      available: true,
      type: INTEGRATION_TYPE.accounting,
    },
    {
      id: 'netsuite',
      name: 'Oracle NetSuite',
      system: 'netsuite',
      platformNames: ['Oracle NetSuite'],
      img: '#netsuite-ico',
      available: true,
      type: INTEGRATION_TYPE.accounting,
      isBeta: true,
    },
    {
      id: 'csv',
      name: 'CSV',
      available: true,
      type: INTEGRATION_TYPE.accounting,
    },
  ],
  business: [],
};

export const GRAPH_COLORS = [
  '#17A977',
  '#2e16a9',
  '#7c9dff',
  '#900c3f',
  '#2196F3',
  '#ff6666',
  '#cc3366',
  '#ff0000',
  '#dd6666',
  '#009966',
  '#ffcc00',
  '#FFEB3B',
  '#ff3399',
  '#00197f',
  '#0b543b',
  '#795548',
  '#9E9E9E',
  '#607D8B',
  '#E91E63',
  '#BA55D3',
  '#FF00FF',
  '#FF69B4',
  '#FF1493',
  '#9400D3',
  '#4B0082',
  '#00FF7F',
  '#00FF00',
  '#00FFFF',
  '#00CED1',
  '#7FFF00',
  '#808000',
  '#0b543b',
  '#ff0011',
  '#c0e4d8',
];

export const POPULAR_CURRENCIES_WITH_COLORS: Record<string, string> = {
  USD: '#7c9dff',
  EUR: '#2e16a9',
  JPY: '#900c3f',
  GBP: '#17A977',
  AUD: '#2196F3',
  CAD: '#ff6666',
  CHF: '#cc3366',
  CNY: '#ff0000',
  HKD: '#dd6666',
  NZD: '#009966',
  SEK: '#ffcc00',
  KRW: '#FFEB3B',
  SGD: '#ff3399',
  NOK: '#00197f',
  MXN: '#0b543b',
  INR: '#795548',
  RUB: '#9E9E9E',
  ZAR: '#607D8B',
  TRY: '#E91E63',
  BRL: '#BA55D3',
  TWD: '#FF00FF',
  DKK: '#FF69B4',
  PLN: '#FF1493',
  THB: '#9400D3',
  MYR: '#4B0082',
  IDR: '#00FF7F',
  HUF: '#00FF00',
  CZK: '#00FFFF',
  ILS: '#00CED1',
  PHP: '#7FFF00',
  AED: '#808000',
  SAR: '#0b543b',
  KWD: '#ff0011',
  OTHER: '#c0e4d8',
};
