import { Dispatch, FC, SetStateAction } from 'react';
import { useTheme } from 'styled-components';
import { Paragraph, Row } from 'components';
import Button from 'components/shared/Button/Button';
import { TableHeader } from 'components/shared/TableHeader/TableHeader.styles';
import { FilterButton } from 'components/shared/FilterButton/FilterButton.styles';
import { CASHFLOW_TYPE_TO_TITLE } from '../../consts';
import { CASHFLOW_TYPES_FOR_FILTER_BUTTONS } from './consts';
import { CASHFLOW_TYPE_NEW } from 'services/cashflows';

interface IOwnProps {
  filter?: CASHFLOW_TYPE_NEW;
  setFilter: Dispatch<SetStateAction<CASHFLOW_TYPE_NEW | undefined>>;
  onGoBack: () => void;
}

const Tabs: FC<IOwnProps> = ({ filter, setFilter, onGoBack }) => {
  const theme = useTheme();

  return (
    <TableHeader>
      <Row gap={theme.spacing.xs}>
        {CASHFLOW_TYPES_FOR_FILTER_BUTTONS.map((type) => (
          <FilterButton
            onClick={() => setFilter(filter === type ? undefined : type)}
            active={filter === type}
          >
            <Paragraph>{CASHFLOW_TYPE_TO_TITLE[type]}</Paragraph>
          </FilterButton>
        ))}
      </Row>
      <Row justifyContent="flex-end" flex={1}>
        <Button
          onClick={onGoBack}
          variant="link"
          ml
          mlValue={theme.spacing.xs}
        >
          Back to main view
        </Button>
      </Row>
    </TableHeader>
  );
};

export default Tabs;
