import { generateSearchQuery, openQuery } from 'utils';
import firestore from './firestore';
import { AxiosPrivateFirebaseInstance } from 'settings';
import { IResponse, ITransaction } from 'types';
import { IWalletTransaction } from 'pages/Statements/components/StatementsTable/types';
import { IPaginatedResponse } from './firebase';

export const getTransactions = (limit?: number) =>
  AxiosPrivateFirebaseInstance.get<IResponse<ITransaction[]>>(
    `/transactions${generateSearchQuery({ limit })}`
  );

interface SubscribeToTransactionsParams {
  entityId: string;
  limit?: number;
  callback: (transactions: any) => void;
}

export const subscribeToTransactions = ({
  entityId,
  limit = 250,
  callback,
}: SubscribeToTransactionsParams) => {
  return firestore
    .collection('transactions')
    .where('_owner', '==', entityId)
    .orderBy('_created', 'desc')
    .limit(limit)
    .onSnapshot((transactions) => callback(openQuery(transactions)));
};

export const getTransactionsCsvByCurrency = async (
  currencies: string,
  dateFrom: string,
  dateTo: string
) =>
  AxiosPrivateFirebaseInstance.get<
    IResponse<Array<{ currency: string; csv: string }>>
  >(
    `/transactions/csv?currencies=${currencies}&dateFrom=${dateFrom}&dateTo=${dateTo}`
  );

export const getTransactionCurrencies = async () =>
  AxiosPrivateFirebaseInstance.get<IResponse<string[]>>(
    `/transactions/currencies`
  );

export const getWalletTransactions = async (query: string) =>
  AxiosPrivateFirebaseInstance.get<
    IResponse<IPaginatedResponse<IWalletTransaction>>
  >(`/wallets/transactions${query}`);

export const getWalletTransactionsPdfByDate = async ({
  currency,
  from,
  to,
}: {
  currency: string;
  from: string;
  to: string;
}) =>
  AxiosPrivateFirebaseInstance.get<Blob>(
    `/wallets/transactions?currency=${currency}&from=${from}&to=${to}`,
    {
      headers: {
        'Content-Type': 'application/pdf',
        Accept: 'application/pdf',
      },
      responseType: 'blob',
    }
  );

export const getWalletCurrencies = async () =>
  AxiosPrivateFirebaseInstance.get<IResponse<string[]>>(`/wallets/currencies`);

export const writeWalletTransactionToSourceSystem = async (
  walletTransactionId: string
) =>
  AxiosPrivateFirebaseInstance.post(
    `/integration/wallet-transactions/${walletTransactionId}/write-to-source-system`
  );
