import { FC, useState } from 'react';
import orderBy from 'lodash.orderby';
import { useTheme } from 'styled-components';
import dayjs from 'dayjs';
import { useStoreState } from 'state';
import { Notify } from 'utils';
import {
  Col,
  Icon,
  InlineLoader,
  Paragraph,
  Row,
  StaleInfo,
  Title,
} from 'components';
import CounterTitle from 'components/shared/CounterTitle/CounterTitle';
import Placeholder from 'components/shared/Placeholder/Placeholder';
import {
  FollowedCurrenciesWrapper,
  CloseButtonWrapper,
} from './FollowedCurrencies.styles';
import { deferSuggestedFollowedCurrency } from 'services/entities';
import ButtonClose from 'components/shared/ButtonClose/ButtonClose';
import Button from 'components/shared/Button/Button';
import FollowedCurrency from '../FollowedCurrency/FollowedCurrency';
import useFollowedCurrencies from '../../../hooks/useFollowedCurrencies';
import { ICurrency, IFollowedCurrencyPair } from 'types';

const getSuggestedCurrencies = ({
  detectedCurrencies,
  currencyByCode,
  deferredSuggestedFollowedCurrencies,
  followedCurrencies,
  entityCurrencyCode,
}: {
  detectedCurrencies: string[];
  currencyByCode: (currencyCode: string) => ICurrency | null;
  deferredSuggestedFollowedCurrencies?: Record<string, string> | null;
  followedCurrencies: IFollowedCurrencyPair[];
  entityCurrencyCode: string | null;
}) => {
  const enabledCurrencies = detectedCurrencies.filter(
    (currency) => currencyByCode(currency)?.enabled
  );
  const enabledCurrenciesSet = new Set(enabledCurrencies);

  followedCurrencies.forEach((item) =>
    enabledCurrenciesSet.delete(item.buyCurrency)
  );

  if (entityCurrencyCode) {
    enabledCurrenciesSet.delete(entityCurrencyCode);
  }

  if (!!deferredSuggestedFollowedCurrencies) {
    Object.keys(deferredSuggestedFollowedCurrencies).forEach(
      (deferredSuggestedFollowedCurrency) => {
        const deferDate =
          deferredSuggestedFollowedCurrencies?.[
            deferredSuggestedFollowedCurrency
          ];

        if (dayjs().isBefore(deferDate)) {
          enabledCurrenciesSet.delete(deferredSuggestedFollowedCurrency);
        }
      }
    );
  }

  return Array.from(enabledCurrenciesSet);
};

const FollowedCurrencies: FC = () => {
  const theme = useTheme();
  const { followedCurrencies, currencyByCode } = useStoreState(
    (state) => state.CurrenciesState
  );
  const { entityId, userEntity, entityCurrencyCode } = useStoreState(
    (state) => state.UserState
  );
  const { invoicesAggregations } = useStoreState(
    (state) => state.InvoicesState
  );
  const { onEditCurrency, onAddCurrency } = useFollowedCurrencies();
  const isCurrenciesMoreThanTwo = followedCurrencies?.length > 2;
  const [isLoading, setIsLoading] = useState(false);
  const { deferredSuggestedFollowedCurrencies } = userEntity;

  const suggestedCurrencies = getSuggestedCurrencies({
    detectedCurrencies: invoicesAggregations
      ? Object.keys(invoicesAggregations.perCurrency)
      : [],
    currencyByCode,
    deferredSuggestedFollowedCurrencies,
    followedCurrencies,
    entityCurrencyCode,
  });

  const onDeferSuggestedFollowedCurrency = async (currencyCode: string) => {
    if (!entityId) {
      return;
    }

    try {
      setIsLoading(true);
      const { data } = await deferSuggestedFollowedCurrency({
        entityId,
        currencyCode,
      });

      if (data.success) {
        Notify.success('Suggestion hidden for 3 months');
      } else if (data?.message) {
        Notify.error(data.message);
      }
    } catch (error: any) {
      if (error?.response?.data?.message) {
        Notify.error(error.response.data.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Row alignItems="flex-start">
        <CounterTitle
          mb
          mbValue={theme.spacing.l}
          title="Currencies"
          count={followedCurrencies?.length}
        />

        {isCurrenciesMoreThanTwo && (
          <Button variant="link" onClick={() => onAddCurrency()}>
            + Add new
          </Button>
        )}
      </Row>

      <FollowedCurrenciesWrapper>
        {orderBy(followedCurrencies, '_created', 'asc').map(
          (followedCurrency, index) => {
            return (
              <FollowedCurrency
                key={followedCurrency.id}
                currency={followedCurrency}
                onEditCurrency={onEditCurrency}
                zIndex={followedCurrencies.length - index}
              />
            );
          }
        )}

        {!suggestedCurrencies.length && !isCurrenciesMoreThanTwo && (
          <Placeholder
            size="small"
            actionText="+ Add new"
            onAction={onAddCurrency}
            description="Track the currency rates and risks"
          />
        )}

        {suggestedCurrencies.map((currencyCode) => {
          const currency = currencyByCode(currencyCode);
          return currency ? (
            <Placeholder
              size="small"
              actionText="+ Add to your Currencies"
              onAction={() => onAddCurrency(currencyCode)}
            >
              <CloseButtonWrapper>
                <StaleInfo
                  infoSize="32px"
                  mode="hover"
                  strategy="fixed"
                  portal
                  placement="top"
                  trigger={
                    isLoading ? (
                      <InlineLoader width="24px" height="24px" />
                    ) : (
                      <ButtonClose
                        onClick={() =>
                          onDeferSuggestedFollowedCurrency(currencyCode)
                        }
                      />
                    )
                  }
                >
                  <Paragraph color="white">Hide this for 3 months</Paragraph>
                </StaleInfo>
              </CloseButtonWrapper>
              <Col gap={theme.spacing.xs} mb mbValue={theme.spacing.xs}>
                <Row justifyContent="center">
                  <Icon icon={currency.countryCode} />
                  <Title variant="h3" ml>
                    {currency.code}
                  </Title>
                </Row>
                <Paragraph color="grey">
                  We’ve found invoices in this currency
                </Paragraph>
              </Col>
            </Placeholder>
          ) : null;
        })}
      </FollowedCurrenciesWrapper>
    </>
  );
};

export default FollowedCurrencies;
