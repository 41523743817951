import { useEffect, useState } from 'react';
import { subscribeToTransactions } from 'services/transactions';
import { useStoreState } from 'state';
import { ITransaction } from 'types';

interface UseSubscribeToTransactionsParams {
  queryLimit?: number;
}

const useSubscribeToTransactions = ({
  queryLimit,
}: UseSubscribeToTransactionsParams) => {
  const { entityId } = useStoreState((state) => state.UserState);
  const [transactions, setTransactions] = useState<ITransaction[]>([]);

  useEffect(() => {
    let unsubscribe: () => void;

    if (entityId) {
      unsubscribe = subscribeToTransactions({
        entityId,
        limit: queryLimit,
        callback: setTransactions,
      });
    }

    return () => unsubscribe?.();
  }, [entityId, queryLimit]);

  return {
    transactions,
    setTransactions,
  };
};

export default useSubscribeToTransactions;
