export enum CONTRACT_STATUS {
  awaitingPrepayment = 'awaiting_prepayment',
  prepaymentOverdue = 'prepayment_overdue',
  readyToUse = 'ready_to_use',
  used = 'used',
  expired = 'expired',
  cancelled = 'cancelled',
}

export interface IContractInput {
  sellAmount: number;
  sellCurrency: string;
  buyAmount: number;
  buyCurrency: string;
  rate: number; // 1.2345
  /** Last date the contract can be used for */
  expiryDate: string; // YYYY-MM-DD
  /** First date the contract can be used for */
  useableFromDate?: string;

  feeRate: number; //0.0089 for 0.89%
  feeAmount: number;
  flexFeeAmount?: number;
  conversionFeeRate: number;

  totalAmount: number; //sellAmount + feeAmount
  prepaymentAmount?: number; //flexFeeAmount

  bookingName?: string;
  invoiceId?: string;
  /**
   * custom notes from the user
   */
  notes?: string;
}

interface IConversionDateExtension {
  newDate: string;
  oldDate: string;
}

export interface IRateContract extends IContractInput {
  id: string;
  _shortId: string; //e.g. CNG7F4R
  _created: any;
  _createdBy: string;
  _updated?: any;
  _updatedBy?: string;
  status: CONTRACT_STATUS;
  remainingBuyAmount: number;
  remainingTotalAmount: number;
  numberOfTransfers: number;
  prepaymentPayByDate: string;
  // Only used for optional Fixed Deposits
  depositRate?: number; // 0.05 for 5%
  depositAmount?: number;
  balanceAmount?: number; // sellAmount - depositAmount
  whenFundsArriveAction?: 'notifyViaEmail' | 'convertAndTransfer';
  conversionDateExtensions?: IConversionDateExtension[];
  excludeFromRisk?: boolean;
}

export interface ICancellationQuote {
  amount: string;
  currency: string;
  event_date_time: string;
}
