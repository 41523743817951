import dayjs from 'dayjs';
import { CellProps, Column, FilterTypes } from 'react-table';
import { BULK_PAYMENT_STATUS, IBulkPayment } from 'types';
import AmountCell from 'components/shared/AmountCell/AmountCell';
import ActionCell from './components/ActionCell/ActionCell';
import CurrenciesCell from './components/CurrenciesCell/CurrenciesCell';
import { InlineActions } from './components/InlineActions/InlineActions';
import StatusCell from './components/StatusCell/StatusCell';
import FundByCell from './components/FundByCell/FundByCell';
import TransfersCell from './components/TransfersCell/TransfersCell';

const isScheduled = (bulkPayment: IBulkPayment) =>
  bulkPayment.status === BULK_PAYMENT_STATUS.awaitingPayment;

const isFailed = (bulkPayment: IBulkPayment) =>
  bulkPayment.status === BULK_PAYMENT_STATUS.transfersFailed;

const isRecent = (bulkPayment: IBulkPayment) =>
  dayjs(bulkPayment._created).isAfter(dayjs().subtract(7, 'days'));

export const paymentRunsTableTileFilterTypes: FilterTypes<IBulkPayment> = {
  buyAmountsBreakdown: (rows, _, filterValue) => {
    if (filterValue === 'scheduled') {
      return rows.filter((row) => isScheduled(row.original));
    }

    if (filterValue === 'failed') {
      return rows.filter((row) => isFailed(row.original));
    }

    if (filterValue === 'recent') {
      return rows.filter((row) => isRecent(row.original));
    }

    return rows;
  },
};

export const generatePaymentRunTableTileColumns = ({
  onCancelBulkPaymentClick,
  onRowClick,
}: {
  onCancelBulkPaymentClick: (bulkPaymentId: string) => void;
  onRowClick: (bulkPayment: IBulkPayment) => void;
}): Column<IBulkPayment>[] => [
  {
    id: 'buyAmountsBreakdown',
    Header: 'Currency',
    Cell: ({ row }: any) => (
      <CurrenciesCell buyAmountsBreakdown={row.original.buyAmountsBreakdown} />
    ),
    disableSortBy: true,
    width: 220,
    minWidth: 220,
    filter: 'buyAmountsBreakdown',
  },
  {
    accessor: 'payAmount',
    Header: 'Funding amount',
    Cell: ({ row, value }) => (
      <AmountCell
        size="large"
        amount={value}
        currencyCode={row.original.sellCurrency}
      />
    ),
    width: 160,
    minWidth: 160,
  },
  {
    Header: 'Transfers',
    disableSortBy: true,
    Cell: ({ row: { original } }: CellProps<IBulkPayment>) => (
      <TransfersCell bulkPayment={original} />
    ),
    width: 160,
    minWidth: 160,
  },
  {
    accessor: 'status',
    Header: 'Status',
    Cell: ({ row: { original } }) => <StatusCell bulkPayment={original} />,
    width: 120,
    minWidth: 100,
    disableSortBy: true,
  },
  {
    accessor: 'fundedTimestamp',
    Header: 'Fund by',
    Cell: ({ row: { original } }) => <FundByCell bulkPayment={original} />,
    width: 150,
    minWidth: 150,
  },
  {
    id: 'actions',
    Header: () => null,
    disableSortBy: true,
    Cell: ({ row: { original } }: CellProps<IBulkPayment>) => (
      <InlineActions bulkPayment={original} onRowClick={onRowClick} />
    ),
    width: 100,
    minWidth: 100,
  },
  {
    id: 'dots',
    Header: () => null,
    disableSortBy: true,
    Cell: ({ row: { original } }: CellProps<IBulkPayment>) => (
      <ActionCell
        status={original.status}
        bulkPaymentId={original.id}
        onCancelClick={onCancelBulkPaymentClick}
      />
    ),
    width: 60,
    minWidth: 55,
  },
];
