import { FC } from 'react';
import { useStoreState } from 'state';
import useUrlValues from 'hooks/useUrlValues';
import ChartRates from './components/ChartRates/ChartRates';
import { TCashflowAtRiskNew } from 'types';
import ChartCashflows from 'components/shared/ChartCashflows/ChartCashflows';
import { TRisksDataPeriod } from 'pages/Risks/types';
import ChartHedgeRatios from './components/ChartHedgeRatios/ChartHedgeRatios';
import { TRisksMonthlyBreakdownContentType } from '../../types';

interface OwnProps {
  cashflowsRisksData: TCashflowAtRiskNew[];
  contentType: TRisksMonthlyBreakdownContentType;
  onChartPeriodClick: (period: string, periodIndex: number) => void;
}

const Charts: FC<OwnProps> = ({
  cashflowsRisksData,
  contentType,
  onChartPeriodClick,
}) => {
  const { userEntity } = useStoreState((state) => state.UserState);
  const { currency, period } = useUrlValues('currency', 'period', 'dateRange');
  const isEntityCurrency = currency === userEntity?.entityCurrency;
  const isAllCurrencies = currency === 'all';

  if (contentType === 'hedgeRatio' && !isEntityCurrency && period !== 'week') {
    return (
      <ChartHedgeRatios
        data={cashflowsRisksData}
        period={period as TRisksDataPeriod}
        onChartClick={onChartPeriodClick}
      />
    );
  }

  if (contentType === 'cashflows') {
    return (
      <ChartCashflows
        data={cashflowsRisksData}
        currencyCode={currency}
        period={period as TRisksDataPeriod}
        onChartClick={onChartPeriodClick}
      />
    );
  }

  if (
    contentType === 'rates' &&
    !isEntityCurrency &&
    !isAllCurrencies &&
    period !== 'week'
  ) {
    return (
      <ChartRates
        data={cashflowsRisksData}
        currencyCode={currency}
        period={period as TRisksDataPeriod}
        onChartClick={onChartPeriodClick}
      />
    );
  }

  return null;
};

export default Charts;
