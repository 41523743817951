import useUrlValues from 'hooks/useUrlValues';
import { Dispatch, FC, SetStateAction } from 'react';
import DateRangeInfo from 'components/shared/DateRangeInfo/DateRangeInfo';
import { TCashflowAtRiskNew } from 'types';
import { Loader } from 'components';
import CashflowsTable from './components/CashflowsTable/CashflowsTable';
import { TRisksDataPeriod } from 'pages/Risks/types';
import { ICashflowFromSearch } from 'services/cashflows';

interface IOwnProps {
  cashflowsRisksData: TCashflowAtRiskNew[];
  isLoadingCashflowsRisksData: boolean;
  isLoadingAndHaveData: boolean;
  activeDateRangeCashflows?: ICashflowFromSearch[];
  onGoBack: () => void;
  setActiveDateRange: Dispatch<SetStateAction<string | undefined>>;
}

const DataTable: FC<IOwnProps> = ({
  cashflowsRisksData,
  isLoadingCashflowsRisksData,
  isLoadingAndHaveData,
  activeDateRangeCashflows,
  onGoBack,
  setActiveDateRange,
}) => {
  const { period } = useUrlValues('period');

  if (isLoadingCashflowsRisksData && !isLoadingAndHaveData) {
    return <Loader size="large" style={{ alignSelf: 'center' }} />;
  }

  if (activeDateRangeCashflows) {
    return (
      <DateRangeInfo data={activeDateRangeCashflows} onGoBack={onGoBack} />
    );
  }

  if (!!period) {
    return (
      <CashflowsTable
        period={period as TRisksDataPeriod}
        cashflowsRisksData={cashflowsRisksData}
        setActiveDateRange={setActiveDateRange}
      />
    );
  }

  return null;
};

export default DataTable;
