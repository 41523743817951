import styled, { css, CSSProperties } from 'styled-components';

export const ActionButton = styled.button<{
  maxWidth?: CSSProperties['maxWidth'];
}>(
  ({ theme, maxWidth = 'unset' }) => css`
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    background: ${theme.color.emeraldLight};
    width: 100%;
    max-width: ${maxWidth};
    transition: ${theme.transition};

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 4px 8px;

    border: 1px solid ${theme.color.greyLight_1};
    border-radius: ${theme.borderRadius.s};

    &:disabled {
      background: ${theme.color.greyLight_1};
      pointer-events: none;
      cursor: default;
    }
  `
);
