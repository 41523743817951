import { Paragraph, ParagraphWithEllipsis } from 'components';
import { Row } from 'components/shared/Row/Row';
import { CellProps, Column, FilterTypes } from 'react-table';
import { ITransaction } from 'types/transactions';
import { parseIntoCurrencyString } from 'utils';
import DropdownMenuCell from '../DropdownMenuCell/DropdownMenuCell';
import isBetween from 'dayjs/plugin/isBetween';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import dayjs, { Dayjs } from 'dayjs';
import { DATE_FORMAT, DB_DATE_FORMAT } from 'variables';
import _isEmpty from 'lodash.isempty';
import { getTransactionTypeTitle } from 'utils/transactions';
import TransactionStatusCell from './components/TransactionStatusCell/TransactionStatusCell';
import CellAmountWithDirection from '../CellAmountWithDirection/CellAmountWithDirection';
import { Dispatch, SetStateAction } from 'react';

dayjs.extend(isBetween);
dayjs.extend(quarterOfYear);

export const generateTableColumns = ({
  setTransactions,
}: {
  setTransactions: Dispatch<SetStateAction<ITransaction[]>>;
}): Column<ITransaction>[] => [
  {
    accessor: 'transactedOn',
    Header: 'Transacted on',
    disableSortBy: true,
    Cell: ({ value }: CellProps<ITransaction>) => (
      <Paragraph>{dayjs(value, DB_DATE_FORMAT).format(DATE_FORMAT)}</Paragraph>
    ),
    width: 80,
    minWidth: 55,
    filter: 'transactedOn',
  },
  {
    accessor: 'settledOn',
    Header: 'Settled on',
    Cell: ({ value }: CellProps<ITransaction>) => (
      <Paragraph>
        {value ? dayjs(value, DB_DATE_FORMAT).format(DATE_FORMAT) : null}
      </Paragraph>
    ),
    width: 156,
    minWidth: 136,
  },
  {
    accessor: 'type',
    Header: 'Type',
    Cell: ({ value }: CellProps<ITransaction>) => (
      <ParagraphWithEllipsis>
        {getTransactionTypeTitle(value)}
      </ParagraphWithEllipsis>
    ),
    width: 156,
    minWidth: 136,
    filter: 'type',
  },
  {
    accessor: 'counterPartyName',
    Header: 'Counterparty name',
    Cell: ({ value }: CellProps<ITransaction>) => (
      <Paragraph>{value}</Paragraph>
    ),
    width: 130,
    minWidth: 110,
  },
  {
    id: 'direction',
    accessor: 'amount',
    Header: 'Amount',
    Cell: ({ value, row }: CellProps<ITransaction>) => (
      <CellAmountWithDirection
        value={value}
        direction={row.original.direction}
        currencyCode={row.original.mainCurrency}
      />
    ),
    width: 120,
    minWidth: 100,
    filter: 'direction',
  },
  {
    accessor: 'fxAmount',
    Header: 'FX Amount',
    Cell: ({ value, row }: CellProps<ITransaction>) =>
      row.original.fxCurrency ? (
        <Paragraph>{`${parseIntoCurrencyString(value)} ${
          row.original.fxCurrency
        }`}</Paragraph>
      ) : null,
    width: 120,
    minWidth: 100,
  },
  {
    accessor: 'reference',
    Header: 'Reference',
    Cell: ({ value }: CellProps<ITransaction>) => (
      <Paragraph>{value}</Paragraph>
    ),
    width: 120,
    minWidth: 100,
  },
  {
    accessor: 'status',
    Header: 'Status',
    disableSortBy: true,
    Cell: ({ value }) => <TransactionStatusCell transactionStatus={value} />,
    width: 120,
    minWidth: 100,
    filter: 'status',
  },
  {
    id: 'dropdown',
    disableSortBy: true,
    Header: () => null,
    Cell: ({ row }: any) => {
      // We don't have popup for fees
      if (row.original.type === 'fee') {
        return null;
      }

      return (
        <Row flex={1} justifyContent="flex-end">
          <DropdownMenuCell
            transaction={row.original}
            setTransactions={setTransactions}
          />
        </Row>
      );
    },
    width: 100,
  },
];

export interface TableFilterOption {
  id: string;
  value: string;
  title: string;
  type: 'checkbox' | 'radio' | 'date' | 'number';
}

export interface TableFilter {
  key: string;
  id: string;
  value: TableFilterOption[];
  title: string;
  options: TableFilterOption[];
}

const transactionsFilterDateOptions: TableFilterOption[] = [
  {
    id: 'dateFrom',
    value: '',
    title: 'Date from',
    type: 'date',
  },
  {
    id: 'dateTo',
    value: '',
    title: 'Date to',
    type: 'date',
  },
  {
    id: 'month',
    value: 'month',
    title: 'This month',
    type: 'radio',
  },
  {
    id: 'quarter',
    value: 'quarter',
    title: 'This quarter',
    type: 'radio',
  },
  {
    id: 'year',
    value: 'year',
    title: 'Year to date',
    type: 'radio',
  },
];

const transactionsFilterDirectionOptions: TableFilterOption[] = [
  {
    id: 'in',
    value: 'in',
    title: 'Incoming',
    type: 'checkbox',
  },
  {
    id: 'out',
    value: 'out',
    title: 'Outgoing',
    type: 'checkbox',
  },
];

const transactionsFilterTypeOptions: TableFilterOption[] = [
  {
    id: 'all',
    value: 'all',
    title: 'All',
    type: 'checkbox',
  },
  {
    id: 'fxExchange',
    value: 'fxExchange',
    title: 'FX Exchange',
    type: 'checkbox',
  },
  {
    id: 'transferIn',
    value: 'transferIn',
    title: 'Transfer in',
    type: 'checkbox',
  },
  {
    id: 'transferOut',
    value: 'transferOut',
    title: 'Transfer out',
    type: 'checkbox',
  },
  {
    id: 'rateContract',
    value: 'rateContract',
    title: 'Prebooking',
    type: 'checkbox',
  },
  {
    id: 'fee',
    value: 'fee',
    title: 'Fee',
    type: 'checkbox',
  },
];

export const staticTransactionsFilters: TableFilter[] = [
  {
    key: 'transactedOn',
    id: 'transactedOn',
    value: [],
    title: 'Transacted on',
    options: transactionsFilterDateOptions,
  },
  {
    key: 'direction',
    id: 'direction',
    value: transactionsFilterDirectionOptions,
    title: 'Direction',
    options: transactionsFilterDirectionOptions,
  },
  {
    key: 'type',
    id: 'type',
    value: transactionsFilterTypeOptions,
    title: 'Payment type',
    options: transactionsFilterTypeOptions,
  },
];

export const staticTransactionsFiltersToRender: TableFilter[] = [
  {
    key: 'transactedOn',
    id: 'transactedOn',
    value: [],
    title: 'Transacted on',
    options: transactionsFilterDateOptions,
  },
  {
    key: 'direction',
    id: 'direction',
    value: transactionsFilterDirectionOptions,
    title: 'Direction',
    options: transactionsFilterDirectionOptions,
  },
  {
    key: 'type',
    id: 'type',
    value: transactionsFilterTypeOptions,
    title: 'Payment type',
    options: transactionsFilterTypeOptions,
  },
];

export const filterTypes: FilterTypes<ITransaction> = {
  transactedOn: (rows, _, filterValue: TableFilterOption[]) => {
    if (filterValue.find((val) => val.id === 'month')) {
      return rows.filter((row) => {
        const value = row.original;

        return dayjs(value.transactedOn).isBetween(
          dayjs(),
          dayjs().startOf('month'),
          'days',
          '[]'
        );
      });
    }

    if (filterValue.find((val) => val.id === 'quarter')) {
      return rows.filter((row) => {
        const value = row.original;

        return dayjs(value.transactedOn).isBetween(
          dayjs(),
          dayjs().startOf('quarter'),
          'days',
          '[]'
        );
      });
    }

    if (filterValue.find((val) => val.id === 'year')) {
      return rows.filter((row) => {
        const value = row.original;

        return dayjs(value.transactedOn).isBetween(
          dayjs(),
          dayjs().startOf('year'),
          'days',
          '[]'
        );
      });
    }

    const dateFromFilterValue = filterValue.find(
      (val) => val.id === 'dateFrom'
    );
    const dateToFilterValue = filterValue.find((val) => val.id === 'dateTo');
    const valuesToFilterBuy: { dateFrom?: Dayjs; dateTo?: Dayjs } = {};

    if (dateFromFilterValue) {
      const dateValue = dayjs(dateFromFilterValue.value, DATE_FORMAT);

      if (dateValue.isValid()) {
        valuesToFilterBuy.dateFrom = dateValue;
      }
    }
    if (dateToFilterValue) {
      const dateValue = dayjs(dateToFilterValue.value, DATE_FORMAT);

      if (dateValue.isValid()) {
        valuesToFilterBuy.dateTo = dateValue;
      }
    }

    if (!_isEmpty(valuesToFilterBuy)) {
      return rows.filter((row) => {
        const value = row.original;
        const valueDate = dayjs(value.transactedOn);
        const { dateFrom, dateTo } = valuesToFilterBuy;

        if (dateFrom && !valueDate.isAfter(dateFrom)) {
          return false;
        }

        if (dateTo && !valueDate.isBefore(dateTo)) {
          return false;
        }

        return true;
      });
    }

    return rows;
  },
  type: (rows, _, filterValue: TableFilterOption[]) => {
    if (filterValue.find((val) => val.id === 'all')) {
      return rows;
    }

    if (filterValue.length) {
      return rows.filter((row) => {
        const value = row.original;

        return filterValue.find((val) => val.value === value.type);
      });
    }

    return [];
  },
  direction: (rows, _, filterValue: TableFilterOption[]) => {
    let rowsToReturn = rows;

    if (filterValue.length) {
      rowsToReturn = rowsToReturn.filter((row) => {
        const value = row.original;

        return filterValue.find((val) => val.id === value.direction);
      });
    }

    return rowsToReturn;
  },
  text: (rows, _, filterValue) => {
    if (!filterValue) {
      return rows;
    }

    return rows.filter((row) => {
      const value = row.original;

      if (
        value.counterPartyName
          ?.toLowerCase()
          .includes(filterValue.toLowerCase())
      ) {
        return true;
      }

      if (value.id.toLowerCase().includes(filterValue.toLowerCase())) {
        return true;
      }

      if (value.amount === Number(filterValue)) {
        return true;
      }

      return false;
    });
  },
};
