import useUrlValues from 'hooks/useUrlValues';
import { FC } from 'react';
import { Loader } from 'components';
import CashflowsTable from './components/CashflowsTable/CashflowsTable';
import { TRisksDataPeriod } from 'pages/Risks/types';
import { IRiskGroupedByEntityData } from 'services/firebase/analysis';
import DateRangeInfo from 'components/shared/DateRangeInfo/DateRangeInfo';
import { ICashflowFromSearch } from 'services/cashflows';

interface IOwnProps {
  cashflowsRisksData: IRiskGroupedByEntityData['perPeriod'];
  entityIds: string[];
  isLoadingCashflowsRisksData: boolean;
  isLoadingAndHaveData: boolean;
  activeDateRangeCashflows?: ICashflowFromSearch[];
  onGoBack: () => void;
}

const DataTable: FC<IOwnProps> = ({
  cashflowsRisksData,
  entityIds,
  isLoadingCashflowsRisksData,
  isLoadingAndHaveData,
  activeDateRangeCashflows,
  onGoBack,
}) => {
  const { period } = useUrlValues('period');

  if (isLoadingCashflowsRisksData && !isLoadingAndHaveData) {
    return <Loader size="large" style={{ alignSelf: 'center' }} />;
  }

  if (activeDateRangeCashflows) {
    return (
      <DateRangeInfo
        data={activeDateRangeCashflows}
        onGoBack={onGoBack}
      />
    );
  }

  if (!!period) {
    return (
      <CashflowsTable
        period={period as TRisksDataPeriod}
        cashflowsRisksData={cashflowsRisksData}
        entityIds={entityIds}
      />
    );
  }

  return null;
};

export default DataTable;
