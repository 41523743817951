import {
  ILimitOrderDoc,
  ILimitOrderApiInput,
  IOrderType,
} from 'types/limitOrders';
import { IResponse } from 'types';
import { AxiosPrivateFirebaseInstance } from 'settings';

export const createLimitOrder = async (payload: ILimitOrderApiInput) =>
  AxiosPrivateFirebaseInstance.post<IResponse<ILimitOrderDoc>>(
    `/limit_orders`,
    payload
  );

export const cancelLimitOrder = async (id: string) =>
  AxiosPrivateFirebaseInstance.post<IResponse<ILimitOrderDoc>>(
    `/limit_orders/${id}/cancel`
  );

export const getLimitOrderById = async (id: string) =>
  AxiosPrivateFirebaseInstance.get<IResponse<ILimitOrderDoc>>(
    `/limit_orders/${id}`
  );

export const getLimitOrders = async (type?: IOrderType) =>
  AxiosPrivateFirebaseInstance.get<IResponse<ILimitOrderDoc[]>>(
    `/limit_orders${type ? `?type=${type}` : ''}`
  );
