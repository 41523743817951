import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { isMobile } from 'react-device-detect';

import { Title, Overflow, Row, Paragraph } from 'components';
import { HexagonLine } from '..';
import { useDebounce } from 'hooks';
import auth from 'services/auth';
import { regex } from 'utils';
import { ERROR_MESSAGES, VALIDATION_MESSAGES } from 'variables';
import Field from 'components/shared/Field/Field.styles';
import Button from 'components/shared/Button/Button';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import Link from 'components/shared/Link/Link';
import { useTheme } from 'styled-components';
import InputBase from 'components/shared/InputBase/InputBase';
import { useStoreState } from 'state';
import MaintenanceBanner from 'components/shared/MaintenanceBanner/MaintenanceBanner';

type Inputs = {
  email: string;
  password: string;
  repeatPassword: string;
  isPolicyAccepted: boolean;
};

interface OwnProps {
  savedValues: any;
  onSaveValues: any;
  onContinueHandler: () => void;
}

const StepOne: FC<OwnProps> = ({
  savedValues,
  onSaveValues,
  onContinueHandler,
}) => {
  const history = useHistory();
  const theme = useTheme();
  const { allowLogin } = useStoreState((state) => state.ReferenceDataState);

  const {
    control,
    watch,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm<Inputs>({
    mode: 'onChange',
    criteriaMode: 'all',
  });

  const onSubmit = (values: any) => {
    onSaveValues(values);
    onContinueHandler();
  };

  const email = watch('email');
  const debounceEmail = useDebounce(email, 500);
  const [emailError, setEmailError] = useState('');
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowRepeatPassword, setIsShowRepeatPassword] = useState(false);
  const password = watch('password');
  const repeatPassword = watch('repeatPassword');

  useEffect(() => {
    if (debounceEmail && regex.email.test(debounceEmail)) {
      auth.fetchSignInMethodsForEmail(debounceEmail).then((data) => {
        if (data.length) {
          setEmailError('Email is already in use.');
        } else {
          setEmailError('');
        }
      });
    }
  }, [debounceEmail]);

  return (
    <Overflow style={{ paddingBottom: 0 }}>
      <MaintenanceBanner />

      {isMobile ? (
        <Title variant="h5">Create your account:</Title>
      ) : (
        <Title>Enter your email and create the password</Title>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Field fluid mt flexDirection="column">
          <Controller
            name="email"
            control={control}
            defaultValue={savedValues.email}
            rules={{
              required: ERROR_MESSAGES.requiredField,
              maxLength: 255,
            }}
            render={({ onChange, value, name }) => (
              <InputBase
                id={name}
                label="Email"
                value={value}
                onChange={onChange}
                disabled={!allowLogin}
                error={emailError || errors.email?.message}
                type="email"
                autoFocus
              />
            )}
          />
        </Field>

        <Field fluid mt mb flexDirection="column">
          <Controller
            name="password"
            control={control}
            defaultValue={savedValues.password}
            rules={{
              validate: {
                maxLength: (value) => value.length <= 50,
                minLength: (value) => value.length >= 8,
                oneUppercase: (value) => /(?=.*[A-Z])/.test(value),
                oneLowercase: (value) => /(?=.*[a-z])/.test(value),
                oneNumber: (value) => /(?=.*\d)/.test(value),
                oneSpecial: (value) => /(?=.*[-+_!@#$%^&*.,?])/.test(value),
              },
            }}
            render={({ onChange, value, name }) => (
              <InputBase
                id={name}
                label="Password"
                value={value}
                onChange={onChange}
                disabled={!allowLogin}
                type={isShowPassword ? 'text' : 'password'}
                icon={isShowPassword ? 'eye-open-ico' : 'eye-closed-ico'}
                onRightButtonClick={() => setIsShowPassword(!isShowPassword)}
              />
            )}
          />
        </Field>

        <ul>
          {VALIDATION_MESSAGES.map(({ id, text, validate }) => {
            return (
              <HexagonLine
                key={id}
                isValid={!!validate(password || savedValues.password)}
                text={text}
                hideWhenValid
              />
            );
          })}
        </ul>

        {(password || savedValues.password) && !errors.password && (
          <>
            <Field fluid mb flexDirection="column">
              <Controller
                name="repeatPassword"
                control={control}
                defaultValue={savedValues.repeatPassword}
                rules={{
                  required: ERROR_MESSAGES.requiredField,
                  validate: {
                    matchesPasswordPassword: (value) => {
                      return (
                        password === value ||
                        savedValues.password === value ||
                        'Passwords have to match'
                      );
                    },
                  },
                }}
                render={({ onChange, value, name }) => (
                  <InputBase
                    id={name}
                    label="Repeat password"
                    value={value}
                    onChange={onChange}
                    disabled={!allowLogin}
                    type={isShowRepeatPassword ? 'text' : 'password'}
                    icon={
                      isShowRepeatPassword ? 'eye-open-ico' : 'eye-closed-ico'
                    }
                    onRightButtonClick={() =>
                      setIsShowRepeatPassword(!isShowRepeatPassword)
                    }
                  />
                )}
              />
            </Field>

            <ul>
              <HexagonLine
                isValid={
                  (repeatPassword || savedValues.repeatPassword) ===
                  (password || savedValues.password)
                }
                text="Passwords have to match"
                hideWhenValid
              />
            </ul>
          </>
        )}

        <Field fluid mt>
          <Controller
            name="isPolicyAccepted"
            control={control}
            rules={{
              required: ERROR_MESSAGES.requiredField,
            }}
            render={({ value, name, onChange, ref }) => (
              <Checkbox
                id={name}
                checked={value}
                onChange={(event) => {
                  onChange(event.target.checked);
                }}
                inputRef={ref}
                label={
                  <>
                    I have read and agree to the HedgeFlows <br />
                    <Link
                      href="https://www.hedgeflows.com/terms-and-conditions"
                      rel="noreferrer"
                      target="_blank"
                      display="inline"
                    >
                      Terms & Conditions
                    </Link>
                    ,{' '}
                    <Link
                      href="https://www.hedgeflows.com/terms-of-use"
                      rel="noreferrer"
                      target="_blank"
                      display="inline"
                    >
                      Terms of Use
                    </Link>{' '}
                    {`and `}
                    <Link
                      href="https://www.hedgeflows.com/privacy-policy"
                      rel="noreferrer"
                      target="_blank"
                      display="inline"
                    >
                      Privacy Policy
                    </Link>
                  </>
                }
              />
            )}
          />
        </Field>

        <Row mt>
          <Button
            flex={1}
            type="submit"
            disabled={!isValid || !!emailError || !allowLogin}
          >
            Continue
          </Button>
        </Row>
      </form>

      <Row mt mtValue="auto" justifyContent="flex-start">
        <Paragraph>Already have an account?</Paragraph>
        <Button
          ml
          mlValue={theme.spacing.xxs}
          variant="link"
          onClick={() => history.push('/login')}
          disabled={!allowLogin}
        >
          Log in
        </Button>
      </Row>
    </Overflow>
  );
};

export default StepOne;
