import { Paragraph, Row } from 'components';
import styled from 'styled-components';

export const InfoBoxWrapper = styled(Row)`
  padding: ${({ theme }) => theme.spacing.m};
  border-radius: ${({ theme }) => theme.borderRadius.m};
  align-items: flex-start;
  background: ${({ theme }) => theme.color.greyLight_2};
`;

export const InfoBoxText = styled(Paragraph)`
  flex: 1;
  margin-left: ${({ theme }) => theme.spacing.s};
`;
