import { Dispatch, FC, SetStateAction, useCallback } from 'react';
import {
  getInvoiceSimpleTransferLink,
  getInvoiceTransferOrSimpleTransferLink,
} from 'utils/links';
import { IContact, IInvoiceFromSearch, PartialContact } from 'types';
import { useHistory, useLocation } from 'react-router-dom';
import { ActionButton } from 'components/shared/ActionButton/ActionButton.styles';
import useInvoicesApprovalStatus from 'hooks/useInvoiceApprovalStatus';
import { isCurrencyEnabledForBuying } from 'utils/currencies';
import { useStoreActions, useStoreState } from 'state';
import useInvoicePrebookAndTransfer from '../../hooks/useInvoicePrebookAndTransfer';
import StaleTooltip from 'components/shared/StaleTooltip/StaleTooltip';
import useInvoiceFromSearchRecord from 'hooks/useInvoiceFromSearchRecord';
import PermissionsChecker from 'components/PermissionsChecker/PermissionsChecker';
import dayjs from 'dayjs';
import { getPartialRecipientFromInvoiceFromSearch } from '../../utils';
import { useTheme } from 'styled-components';
import { Row } from 'components/shared/Row/Row';
import { Paragraph } from 'components/shared/Typography/Typography';
import { errorHandler } from 'utils/errors';
import {
  useCreateTransfersPermissionsCheck,
  useUpdatePaymentRunPermissionsCheck,
  useCreatePaymentRunPermissionsCheck,
} from 'hooks/useSpecificPermissionsCheck';
import {
  deletePaymentRunInvoice,
  addInvoicesToPaymentRun,
  getDraftPaymentRun,
} from 'services/paymentRuns';
import { Notify } from 'utils';
import {
  isInvoiceStatusInPayableState,
  isPayableInvoice,
} from 'utils/invoices';

interface OwnProps {
  record: IInvoiceFromSearch;
  updateInMemoryInvoices: (
    updateFunction: (invoices: IInvoiceFromSearch[]) => IInvoiceFromSearch[]
  ) => void;
  setContactForEdit?: Dispatch<
    SetStateAction<IContact | PartialContact | null>
  >;
  setInvoiceForAddContact?: Dispatch<SetStateAction<IInvoiceFromSearch | null>>;
  paymentRunId?: string;
  draftPaymentRunInvoiceIds?: string[];
}

const DoActionCell: FC<OwnProps> = ({
  record,
  updateInMemoryInvoices,
  setContactForEdit,
  setInvoiceForAddContact,
  paymentRunId,
  draftPaymentRunInvoiceIds,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const { pathname } = useLocation();
  const risks = pathname.includes('risks');
  const { currencies } = useStoreState((state) => state.CurrenciesState);
  const { balanceByCurrencyCode } = useStoreState(
    ({ BalancesState }) => BalancesState
  );
  const {
    user,
    userEntity,
    entityCurrencyCode,
    isEntityEnabled,
    entityId,
    hasApprovalFlow,
    isAutomationPackageEnabled,
    isFxManagementPackageEnabled,
  } = useStoreState(({ UserState }) => UserState);
  const { setShowLimitedAccess } = useStoreActions(
    ({ UtilsState }) => UtilsState
  );
  const { isUpdatingPaymentRun } = useStoreState(
    (state) => state.PaymentRunsState
  );
  const { setState } = useStoreActions((state) => state.PaymentRunsState);

  const { recipientById } = useStoreState(
    ({ RecipientsState }) => RecipientsState
  );
  const canCreateTransfers = useCreateTransfersPermissionsCheck();
  const canUpdatePaymentRun = useUpdatePaymentRunPermissionsCheck();
  const canCreatePaymentRun = useCreatePaymentRunPermissionsCheck();

  const { goToInvoicePrebook } = useInvoicePrebookAndTransfer();
  const {
    isUpdatingInvoicesApprovalStatus,
    updateInvoicesApprovalStatus,
  } = useInvoicesApprovalStatus();
  const {
    isDisabled,
    isSameCurrency,
    isPayable,
    isCanBePaid,
    isApprovable,
    isApprovableByCurrentUser,
    isPrebookable,
    submittableForReview,
    isReceivable,
  } = useInvoiceFromSearchRecord({ record });
  const isInvoiceCurrencyEnabledForBuying = isCurrencyEnabledForBuying({
    currencyCode: record.currency,
    currencies,
  });

  const balanceByCurrency = balanceByCurrencyCode(record.currency);
  const isInvoiceWithContact = !!record.contactId;
  const canPayWithBalance =
    isCanBePaid && !isSameCurrency && !!balanceByCurrency;

  const requiredNumberOfApprovalsOnUserEntity =
    userEntity?.approvalSettings?.requiredNumberOfApprovals || 1;

  const recipientToUse = getPartialRecipientFromInvoiceFromSearch({
    recipientById,
    invoice: record,
  });

  const removeInvoiceFromPaymentRun = useCallback(
    async (paymentRunInvoiceId: string) => {
      if (paymentRunId) {
        try {
          setState(['isUpdatingPaymentRun', true]);
          await deletePaymentRunInvoice({
            paymentRunId: paymentRunId,
            paymentRunInvoiceId,
            regeneratePaymentRun: false,
          });
          updateInMemoryInvoices((invoices) =>
            invoices.filter((invoice) => invoice.id !== paymentRunInvoiceId)
          );
        } catch (error: any) {
          errorHandler(error);
        } finally {
          setState(['isUpdatingPaymentRun', false]);
        }
      }
    },
    [paymentRunId, updateInMemoryInvoices, setState]
  );

  const onAddToPaymentRun = async () => {
    if (!isEntityEnabled) {
      setShowLimitedAccess(true);
      return;
    }

    try {
      setState(['isUpdatingPaymentRun', true]);

      if (!entityId) {
        return;
      }
      const { data: response } = await getDraftPaymentRun({ entityId });

      if (!response.data) {
        return;
      }

      await addInvoicesToPaymentRun({
        paymentRunId: response.data.id,
        invoiceIds: [record.id],
      });

      Notify.success('Invoices added to payment run');
    } catch (error: any) {
      errorHandler(error);
    } finally {
      setState(['isUpdatingPaymentRun', false]);
    }
  };

  const renderContent = () => {
    if (isDisabled) {
      return null;
    }

    if (paymentRunId) {
      return (
        <PermissionsChecker action="update" resource="payment_runs">
          <ActionButton
            disabled={isUpdatingPaymentRun}
            onClick={() => removeInvoiceFromPaymentRun(record.id)}
          >
            Remove
          </ActionButton>
        </PermissionsChecker>
      );
    }

    if (
      !isReceivable &&
      ((recipientToUse && !recipientToUse.enabled) || !isInvoiceWithContact)
    ) {
      return (
        <ActionButton
          as="button"
          onClick={(e: any) => {
            e.stopPropagation();

            // contacts with ID from invoices
            if (
              record.contactId &&
              recipientToUse?.enabled &&
              recipientToUse?.id
            ) {
              setContactForEdit?.(recipientToUse);
            } else {
              setInvoiceForAddContact?.(record);
            }
          }}
        >
          Add recipient
        </ActionButton>
      );
    }

    if (submittableForReview) {
      return (
        <ActionButton
          disabled={isUpdatingInvoicesApprovalStatus}
          onClick={(event) => {
            event.stopPropagation();
            updateInvoicesApprovalStatus({
              invoiceIds: [record.id],
              approvalStatus: 'submitted',
            }).then(() =>
              updateInMemoryInvoices((invoices) =>
                invoices.map((invoice) => {
                  if (invoice.id === record.id) {
                    return {
                      ...invoice,
                      approvalStatus: 'submitted',
                    };
                  }
                  return invoice;
                })
              )
            );
          }}
        >
          Submit
        </ActionButton>
      );
    }

    if (isApprovable) {
      return (
        <StaleTooltip
          content={<Paragraph color="white">Awaiting 2nd Approval</Paragraph>}
          placement="left"
          disabled={isApprovableByCurrentUser}
          strategy="absolute"
          containerWidth="100%"
        >
          <ActionButton
            disabled={
              isUpdatingInvoicesApprovalStatus || !isApprovableByCurrentUser
            }
            onClick={async (event) => {
              event.stopPropagation();
              await updateInvoicesApprovalStatus({
                invoiceIds: [record.id],
                approvalStatus: 'approved',
              });

              updateInMemoryInvoices((invoices) =>
                invoices.map((invoice) => {
                  if (invoice.id === record.id) {
                    return {
                      ...invoice,
                      approvalStatus:
                        requiredNumberOfApprovalsOnUserEntity === 2 &&
                        record.approvedBy
                          ? 'approved'
                          : 'submitted',
                      approvedBy: record.approvedBy
                        ? record.approvedBy
                        : user?.id,
                      approvedAt: record.approvedAt
                        ? record.approvedAt
                        : dayjs().toISOString(),
                      approvedBy2: record.approvedBy ? user?.id : '',
                      approvedAt2: record.approvedAt2
                        ? dayjs().toISOString()
                        : '',
                    };
                  }
                  return invoice;
                })
              );
            }}
          >
            Approve
          </ActionButton>
        </StaleTooltip>
      );
    }

    if (
      isInvoiceCurrencyEnabledForBuying &&
      isCanBePaid &&
      isPayable &&
      isInvoiceWithContact &&
      canCreateTransfers
    ) {
      return (
        <Row flex={1} gap={theme.spacing.xxs}>
          <ActionButton
            disabled={isUpdatingInvoicesApprovalStatus}
            onClick={(event) => {
              event.stopPropagation();

              history.push(
                getInvoiceTransferOrSimpleTransferLink(
                  record,
                  entityCurrencyCode
                )
              );
            }}
          >
            Pay {canPayWithBalance ? entityCurrencyCode : ''}
          </ActionButton>

          {canPayWithBalance && (
            <ActionButton
              disabled={isUpdatingInvoicesApprovalStatus}
              onClick={(event) => {
                event.stopPropagation();

                history.push(getInvoiceSimpleTransferLink(record));
              }}
            >
              Pay {record.currency}
            </ActionButton>
          )}
        </Row>
      );
    }

    if (
      !isSameCurrency &&
      isPrebookable &&
      !!risks &&
      isInvoiceCurrencyEnabledForBuying
    ) {
      return (
        <PermissionsChecker action="create" resource="prebooks">
          <ActionButton
            disabled={isUpdatingInvoicesApprovalStatus}
            onClick={(event) => {
              event.stopPropagation();
              goToInvoicePrebook(record);
            }}
          >
            Book
          </ActionButton>
        </PermissionsChecker>
      );
    }

    if (
      /** If user have permissions to create and update payment runs but can't create transfers
       * Check if invoice can be added to payment run */
      isPayableInvoice(record) &&
      isInvoiceStatusInPayableState(record) &&
      !!recipientToUse?.enabled &&
      !recipientToUse?.shouldPausePayments &&
      (isAutomationPackageEnabled || isFxManagementPackageEnabled) &&
      (!hasApprovalFlow ||
        (hasApprovalFlow && record.approvalStatus === 'approved')) &&
      !draftPaymentRunInvoiceIds?.includes(record.id) &&
      /** Check if user has permissions to create and update payment runs */
      canCreatePaymentRun &&
      canUpdatePaymentRun &&
      /** Check if user has permissions to create transfers */
      !canCreateTransfers
    ) {
      return (
        <ActionButton
          disabled={isUpdatingPaymentRun}
          onClick={onAddToPaymentRun}
        >
          Add to draft run
        </ActionButton>
      );
    }

    return null;
  };

  return <>{renderContent()}</>;
};

export default DoActionCell;
