import { FC, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { Loader, Paragraph, Row } from 'components';
import Button from '../Button/Button';
import Popup from '../Popup/Popup';
import { Subtitle, Title } from '../Typography/Typography';
import { useStoreState } from 'state';
import {
  getContractCancellationQuote,
  cancelContract,
} from 'services/firebase/rateContracts';
import {
  getConversionCancellationQuote,
  cancelConversion,
} from 'services/firebase/conversions';
import { parseIntoCurrencyString, Notify } from 'utils';
import { ICancellationQuote } from 'types';

interface OwnProps {
  onClose?: (isCancelled?: boolean) => void;
  type?: 'contract' | 'conversion';
  cancelRecordId: string;
}

const CancellingBalance: FC<OwnProps> = ({ onClose, type, cancelRecordId }) => {
  const theme = useTheme();
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const [isLoading, setIsLoading] = useState(false);
  const [
    cancellationQuote,
    setCancellationQuote,
  ] = useState<ICancellationQuote | null>(null);

  useEffect(() => {
    const fetchCancellationQuote = async () => {
      if (!cancelRecordId) {
        return;
      }

      try {
        setIsLoading(true);
        let response;

        if (type === 'conversion') {
          response = await getConversionCancellationQuote(cancelRecordId);
        } else {
          response = await getContractCancellationQuote(cancelRecordId);
        }

        if (response.data?.data) {
          setCancellationQuote(response.data.data);
        }
      } catch (error) {
        console.error('Error fetching cancellation quote:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCancellationQuote();
  }, [cancelRecordId, type]);

  const onConfirmCancel = async () => {
    if (!cancelRecordId) {
      return;
    }

    try {
      setIsLoading(true);

      let result;
      if (type === 'conversion') {
        result = await cancelConversion(cancelRecordId);
        if (result.data.success) {
          Notify.success('Conversion cancelled successfully');
          onClose?.(true);
        } else {
          Notify.error('Failed to cancel conversion');
        }
      } else {
        result = await cancelContract(cancelRecordId);
        if (result.data.success) {
          Notify.success('Contract cancelled successfully');
          onClose?.(true);
        } else {
          Notify.error('Failed to cancel contract');
        }
      }
    } catch (error) {
      Notify.error('Failed to cancel');
    } finally {
      setIsLoading(false);
    }
  };

  const currency = cancellationQuote?.currency
    ? currencyByCode(cancellationQuote.currency)
    : null;

  const title =
    type === 'conversion' ? 'Cancel FX Exchange' : 'Cancel Prebooking';
  const confirmText = type === 'conversion' ? 'FX exchange' : 'prebooking';

  return (
    <Popup
      HeaderContent={<Title variant="h3">{title}</Title>}
      FooterContent={
        <Row gap={theme.spacing.m} flex={1}>
          <Button
            variant="secondary"
            onClick={() => onClose?.(false)}
            disabled={isLoading}
          >
            Close
          </Button>
          <Button
            onClick={onConfirmCancel}
            disabled={isLoading}
            isLoading={isLoading}
          >
            Confirm
          </Button>
        </Row>
      }
      width="439px"
      height="300px"
      onClose={() => onClose?.(false)}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Paragraph>
            Are you sure you want to cancel this {confirmText}?
          </Paragraph>

          {cancellationQuote && currency && (
            <Row justifyContent="flex-start" alignItems="baseline">
              <Paragraph color="red" mt>
                Cancellation cost:
              </Paragraph>
              <Subtitle color="red" variant="bold" ml>
                {currency.symbol}
                {parseIntoCurrencyString(parseFloat(cancellationQuote.amount))}
              </Subtitle>
            </Row>
          )}
        </>
      )}
    </Popup>
  );
};

export default CancellingBalance;
