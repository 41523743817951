import { IContractInput, IRateContract, ICurrency, IResponse, ICancellationQuote } from 'types';
import { openQueryWithTimestamp } from 'utils';
import { AxiosPrivateFirebaseInstance } from 'settings';
import db from 'services/firestore';
import { errorHandler } from 'utils/errors';

export const createRateContract = async (
  payload: IContractInput
): Promise<IResponse | undefined> => {
  try {
    const { data } = await AxiosPrivateFirebaseInstance.post<IResponse>(
      `${process.env.REACT_APP_CLOUD_FUNCTIONS_BASE_URL}/contracts`,
      payload
    );

    if (data.success) {
      return data;
    } else {
      errorHandler(data);
    }
  } catch (error: any) {
    errorHandler(error);
  }
};

export interface SubscribeToRateContractsParams {
  entityId: string;
  callback: (rateContracts: IRateContract[]) => void;
}

export const subscribeToRateContracts = ({
  entityId,
  callback,
}: SubscribeToRateContractsParams) =>
  db
    .collection('rateContracts')
    .where('_owner', '==', entityId)
    .onSnapshot((query) => callback(openQueryWithTimestamp(query)));

export const cancelContract = async (contractId: string) =>
  await AxiosPrivateFirebaseInstance.post<IResponse>(
    `/contracts/${contractId}/cancel`
  );

export interface HedgeCashflowsParams {
  buyCurrency: ICurrency['code'];
}
export const hedgeCashflows = async ({ buyCurrency }: HedgeCashflowsParams) => {
  await AxiosPrivateFirebaseInstance.post(
    `/contracts/hedgecashflows/${buyCurrency}`
  );
};

export interface HedgeCashflowsByIdsParams {
  invoiceIds: string[];
}
export const hedgeCashflowsByIds = async (
  payload: HedgeCashflowsByIdsParams
) => {
  return AxiosPrivateFirebaseInstance.post<
    IResponse<{
      successes: {
        invoiceId: string;
        prebook: IRateContract | undefined;
      }[];
      failures: {
        invoiceId: string;
        error: string | undefined;
      }[];
    }>
  >(`/contracts/hedgecashflows`, payload);
};

export const exchangeCurrency = async (payload: IContractInput) => {
  return AxiosPrivateFirebaseInstance.post<IResponse>(
    '/contracts/exchange-currency',
    payload
  );
};

interface GetConversionDatesParams {
  currencyPair: string;
}

export const getConversionDates = async ({
  currencyPair,
}: GetConversionDatesParams) => {
  return AxiosPrivateFirebaseInstance.get<IResponse>(
    `/contracts/conversion-dates/${currencyPair}`
  );
};

export interface IUpdateRateContractParams {
  id: string;
  data: Partial<
    Pick<IRateContract, 'whenFundsArriveAction' | 'excludeFromRisk'>
  >;
}

export const updateRateContract = async ({
  id,
  data,
}: IUpdateRateContractParams) =>
  AxiosPrivateFirebaseInstance.put<IResponse>(`/contracts/${id}`, data);

export interface IExtendedContractDetails {
  buyAmount: number;
  contractId: string;
  dateToExtendTo: string;
  marketRate: number;
  rate: number;
  sellAmount: number;
  totalAmount: number;
  whichAmountToAdjust: 'sell' | 'buy';
  remainingTotalAmount: number;
  remainingBuyAmount: number;
}

export const getMoveContractDueDateDetails = async ({
  contractId,
  dateToExtendTo,
  whichAmountToAdjust,
}: {
  contractId: string;
  dateToExtendTo: string;
  whichAmountToAdjust: 'sell' | 'buy';
}) =>
  AxiosPrivateFirebaseInstance.get<IResponse<IExtendedContractDetails>>(
    `/contracts/${contractId}/date-move/${dateToExtendTo}/${whichAmountToAdjust}`
  );

export const moveContractDueDate = async ({
  contractId,
  ...payload
}: {
  contractId: string;
  dateToExtendTo: string;
  whichAmountToAdjust: 'sell' | 'buy';
  remainingTotalAmount: number;
  remainingBuyAmount: number;
  rate: number;
  reason: string;
}) =>
  AxiosPrivateFirebaseInstance.post<IResponse<IRateContract>>(
    `/contracts/${contractId}/date-move`,
    payload
  );

export const getContractCancellationQuote = async (contractId: string) =>
  AxiosPrivateFirebaseInstance.get<IResponse<ICancellationQuote>>(
    `/contracts/${contractId}/cancellation-quote`
  );
