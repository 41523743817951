import { FC } from 'react';
import { useStoreState } from 'state';
import { Paragraph } from '../Typography/Typography';
import { MaintenanceBannerWrapper } from './MaintenanceBanner.styles';

interface MaintenanceBannerProps {
  fullWidth?: boolean;
}

const MaintenanceBanner: FC<MaintenanceBannerProps> = ({
  fullWidth = true,
}) => {
  const { systemVariables } = useStoreState(
    (state) => state.ReferenceDataState
  );

  if (!systemVariables?.maintenance?.showAnnouncement) {
    return null;
  }

  return (
    <MaintenanceBannerWrapper fullWidth={fullWidth}>
      <Paragraph>{systemVariables?.maintenance?.announcement}</Paragraph>
    </MaintenanceBannerWrapper>
  );
};

export default MaintenanceBanner;
