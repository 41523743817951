import dayjs from 'dayjs';
import { Paragraph, Row, StaleInfo, Col } from 'components';
import { IBulkPayment, BULK_PAYMENT_STATUS } from 'types';
import { firstCharacterToUppercase } from 'utils';
import { useStoreState } from 'state';
import { DATE_FORMAT, TIME_FORMAT } from 'variables';
import { MAP_BULK_PAYMENT_STATUS_TO_TEXT } from '../../constants';

const StatusCell: React.FC<{
  bulkPayment: IBulkPayment;
}> = ({ bulkPayment }) => {
  const { userEntity, hasPaymentRunApprovalFlow, entityUsers } = useStoreState(
    ({ UserState }) => UserState
  );

  const requiredNumberOfApprovalsForPaymentRuns =
    userEntity?.approvalSettings?.requiredNumberOfApprovalsForPaymentRuns || 1;

  const moreThanOneApprover = requiredNumberOfApprovalsForPaymentRuns > 1;

  const approvedBy = bulkPayment.approval?.approvedBy;
  const approvedBy2 = bulkPayment.approval?.approvedBy2;
  const approvedAt = bulkPayment.approval?.approvedAt;
  const approvedAt2 = bulkPayment.approval?.approvedAt2;
  const approvalStatus = bulkPayment.approval?.status;

  const approvedByName = entityUsers.find(
    (entityUser) => entityUser.id === approvedBy
  )?.name;
  const approvedBy2Name = entityUsers.find(
    (entityUser) => entityUser.id === approvedBy2
  )?.name;

  const showApprovalStatus =
    hasPaymentRunApprovalFlow &&
    (bulkPayment.status === BULK_PAYMENT_STATUS.awaitingPayment ||
      bulkPayment.status === BULK_PAYMENT_STATUS.fundingInitiated);

  const rootStatus = MAP_BULK_PAYMENT_STATUS_TO_TEXT[bulkPayment.status];

  // Generate the approval status text
  const getApprovalStatusText = () => {
    if (approvalStatus === 'submitted') {
      const pendingApprovals = approvedBy
        ? requiredNumberOfApprovalsForPaymentRuns - 1
        : requiredNumberOfApprovalsForPaymentRuns;
      return `${pendingApprovals} Approval(s) pending`;
    } else if (approvalStatus) {
      return firstCharacterToUppercase(approvalStatus);
    } else {
      return 'Needs approval';
    }
  };

  return (
    <StaleInfo
      disabled={!approvalStatus}
      mode="hover"
      strategy="fixed"
      portal
      placement="top"
      infoSize="auto"
      trigger={
        <Row>
          {showApprovalStatus && (
            <Paragraph>{getApprovalStatusText()}</Paragraph>
          )}

          {!showApprovalStatus && <Paragraph>{rootStatus}</Paragraph>}
        </Row>
      }
    >
      <Col>
        <Paragraph color="white">
          Approver {moreThanOneApprover ? '1' : ''}: {approvedByName || '-'}
        </Paragraph>
        <Paragraph color="white">
          Approved at:{' '}
          {approvedAt
            ? dayjs(approvedAt).format(`${DATE_FORMAT} ${TIME_FORMAT}`)
            : '-'}
        </Paragraph>

        {moreThanOneApprover && (
          <>
            <Paragraph mt color="white">
              Approver 2: {approvedBy2Name || '-'}
            </Paragraph>
            <Paragraph color="white">
              Approved at:{' '}
              {approvedAt2
                ? dayjs(approvedAt2).format(`${DATE_FORMAT} ${TIME_FORMAT}`)
                : '-'}
            </Paragraph>
          </>
        )}
      </Col>
    </StaleInfo>
  );
};

export default StatusCell;
